import React from "react";
import style from "./userGuide.module.scss";

const LoanProtectUserGuide = () => (
  // <div className={style.UserGuideContainer}>
  //   <div className={style.title}>User Guide</div>
  <>
    <div className={style.contentTitle}>The Underwriting Engine (UE)</div>
    <div className={style.paragraph}>
      Machine Cover offers underwriting engines to assist actuaries,
      underwriters and providers of capacity in general. The one you’re looking
      at, is focused on a product called LoanProtect.
    </div>

    <div className={style.contentTitle}>Underwriting Data-Driven Products</div>
    <div className={style.paragraph}>
      In general, data-driven products tend to be more profitable than is usual
      for P&C because there is no claims adjustment, no operations, no
      per-policy underwriting, and the process is automated.
      <br />
      <br />
      However, the analysis needed prior to taking a such a product to market,
      differs considerably from what is usual for an indemnity-oriented P&C
      product. A key difference is that there is no need to analyze both
      frequency as well as severity of loss, because severity is predetermined
      (by the payout amount) and therefore the problem reduces to analyzing
      frequency only. Therefore, we focus on frequency, i.e. determining the
      probability that the relevant product will trigger (and thus cause a
      payout) at some points and in some locations, within the 12 months that it
      is in effect.
      <br />
      <br />
      A key point to bear in mind is that conventional P&C products generally
      provide coverage for defined perils and their effect upon the
      policyholder. Unfortunately, life doesn’t work that way - it is nearly
      impossible to list and quantify all the ‘bad things’ that might happen to
      a business. If a business building burns down, that may be covered, while
      if a flood washes away the roads leading into a town, that may not be
      covered - and yet both could be devastating when looked at from the
      viewpoint of the business owner.
      <br />
      <br />
      LoanProtect focuses on the economic behavior of the neighborhood to which
      the policyholder belongs - not the particular peril(s) that led to it. In
      that way, LoanProtect lines up much better with the pain experienced by
      the policyholder - because the policyholder is less concerned with what
      peril occurred, than the fact that they are suffering.
    </div>

    <div className={style.contentTitle}>LoanProtect Summary</div>
    <div className={style.paragraph}>
      The US currently has roughly 2.1 million ‘small’ commercial loans
      outstanding, with an aggregate amount owed of around $1.4 trillion
      (principal + interest). Small is defined as $5 million or less. When
      someone takes a commercial loan there are very limited forms of insurance
      available - for instance, an insurance product is available whereby if the
      borrower dies or is disabled, the repayment stream is dealt with by the
      insurer.
      <br />
      <br />
      Small commercial loans are generally used in a variety of ways - to buys
      individual buildings and strip malls, to buy machinery or competitors, to
      lease a variety of equipment, and so on. There is a major risk to the
      borrower that is not currently dealt with via insurance even though it is
      ‘fortuitous’ - the fact that at some point during the 5 to 10-year
      repayment period, the area where the borrower’s business operates, suffers
      an economic shock (for example the loan was to purchase a strip mall so
      the repayment depends on rental income - but if the stores close for
      whatever reason, their rent disappears and the repayment is imperiled…).
      This is particularly relevant to borrowers that depend upon local activity
      (which is most borrowers).
      <br />
      <br />
      This is an enormous opportunity for an insurance product but until now
      there was no way to measure ‘local economic shock’ and now there is.
    </div>

    <div className={style.contentTitle}>How LoanProtect Works</div>
    <div className={style.paragraph}>
      When a borrower takes a commercial loan, there is usually a monthly
      repayment sequence. Let us say that this involves paying $5,000 each
      month. If the borrower also takes LoanProtect, they pay (say) $5,125 each
      month instead of $5,000.
      <br />
      <br />
      Each month, their LoanProtect is checked against our US-wide database of
      card transactions and if it triggers (sharp economic drop,
      non-predictable, drop extends…) then the insurer makes several payments on
      behalf of the borrower.
    </div>

    <div className={style.contentTitle}>Borrower Benefits</div>
    <div className={style.paragraph}>
      <ul>
        <li>
          When the borrower takes the loan, they have no way to predict the
          economic behavior of their neighborhood over the next several years,
          but they are on the hook for repayment, nonetheless. With LoanProtect
          they avoid the risk of foreclosure/repossession/seizure of collateral
          etc. because it gives them a ‘lifeline’ for a defined number of months
          (say 1 year).
        </li>
        <li>
          Given that they have to pay a significant sum each month anyway, the
          addition of an amount for LoanProtect is not (relatively speaking)
          that onerous.
        </li>
        <li>
          LoanProtect gives them the assurance that if their area dips for an
          extended period, they are covered and can pick up again after the dip.
        </li>
      </ul>
    </div>

    <div className={style.contentTitle}>Lender Benefits</div>
    <div className={style.paragraph}>
      <ul>
        <li>
          The lender is usually one of the 10,000 banks and credit unions
          operating in the US. Each time they place a loan that contains
          LoanProtect, they get paid for the insurance placement (just like they
          do today for other types of loan insurance like PMI).
        </li>
        <li>
          They can supplement their revenue significantly with the LoanProtect
          fees because they can ‘force-place’ (as with PMI) or ‘suggest’
          placement.
        </li>
        <li>
          Their risk of loan default drops because the insurer picks up
          repayment during down periods.
        </li>
      </ul>
    </div>

    <div className={style.contentTitle}>Insurer Benefits</div>
    <div className={style.paragraph}>
      <ul>
        <li>
          The insurer usually pays less to banks for distribution than they
          would if selling through insurance brokers.
        </li>
        <li>
          The issue of demand is substantially reduced due to force-placement or
          ‘suggestion’.
        </li>
        <li>
          There is zero competition and it is also difficult for anyone to come
          up with a competing product.
        </li>
      </ul>
    </div>

    <div className={style.contentTitle}>Background</div>
    <div className={style.paragraph}>
      The land area of the US is approximately 3.8 million square miles. We
      divide this area into roughly 3 million squares. Since we have their
      locations, we can assign any borrower of a commercial loan to one of these
      squares. As one might expect, most squares have no borrowers (e.g. they
      may be forest, desert, farmland, water) while some squares may have dozens
      of them (e.g. the heart of a city).
      <br />
      <br />
      LoanProtect is based upon tens of billions of individual card transactions
      across the US. This data is aggregated by square, filtered and analyzed,
      both via machine-learning algorithms as well as via classical statistics.
      Thus, we’re able to track economic activity, down to the square mile,
      anywhere in the US.
      <br />
      <br />
      The basic idea is that we consider the square within which a given
      borrower happens to be. If this square is well-supplied with merchants of
      all kinds and if these merchants in aggregate, see a sharp fall in card
      usage (both in the sense of how often used and how much charged) compared
      to the nation in general, then clearly something bad is going on in that
      neighborhood - which allows the creation of an insurance product like
      LoanProtect.
    </div>

    <div className={style.contentTitle}>Data Source</div>
    <div className={style.paragraph}>
      We get our ‘raw’ data from Mastercard, updated weekly, generally 1-2 weeks
      behind ‘real-time’. The data covers the period from 1/1/2018 onward and is
      reported for every 24-hour period.
      <br />
      <br />
      The data provider automatically skips data for squares where there are
      either no merchants or where there are so few merchants that anyone with
      access to the data could infer which business was implied (e.g. if a given
      square has only one gas station, then any purchase of ‘gasoline and
      related automotive products’ could only have occurred there).
      <br />
      <br />
      As a result, for most days, we typically get data relating to roughly
      100,000 squares. However, merely because a square passes muster with the
      data provider does not mean that it also passes muster with Machine Cover.
      Thus, we run the data through a series of additional filters which leave
      us with approximately 22,000 ‘LoanProtect-qualified squares, i.e. squares
      in which LoanProtects can be sold and serviced because those squares
      contain card-transaction data that is sufficiently rich and diverse for
      us. These squares are mainly in urban and suburban areas, where we might
      also reasonably expect to sell LoanProtects.
    </div>

    <div className={style.contentTitle}>Other things to keep in mind</div>
    <div className={style.paragraph}>
      <ul>
        <li>
          LoanProtect, as the name implies, is supplemental to the taking of a
          commercial loan/lease. Although we impose no limits on size, for
          simplicity we assume that all of these loans fall into the ‘small’
          category, i.e. they have a principal amount of $5 million or less.
        </li>
        <li>It is one-and-done, i.e. if it triggers, it ends immediately.</li>
        <li>
          The thing that triggers is a square - which causes any LoanProtects
          that happen to have been sold into that square, to also trigger.
        </li>
        <li>
          Any time a square triggers, that square is automatically deemed
          ‘LoanProtect-unqualified’ for the next 365 days, i.e. we do not want
          to sell (new) LoanProtects into squares that are already doing
          demonstrably badly.
        </li>
      </ul>
    </div>

    <div className={style.contentTitle}>
      Using the Underwriting Engine for LoanProtect
    </div>
    <div className={style.paragraph}>
      Once a user of the Underwriting Engine has logged in and selected
      LoanProtect, it works as follows:
    </div>

    <div className={style.contentTitle}>SETTINGS</div>
    <div className={style.paragraph}>
      The user may choose to alter the SETTINGS, which are available by clicking
      on SETTINGS in the LoanProtect box BEFORE clicking on SELECT. This is only
      necessary when LoanProtect has already been in the market for long enough
      that it has accumulated a significant sales history which can be taken
      into account while underwriting.
      <br />
      <br />
      Clicking on SETTINGS leads to a page where LoanProtect can be operated
      upon 3 different kinds of 'raw data' with different weightages for each.
      <ol type="1">
        <li>
          The first is the 'raw data' on card activity which provides
          information about economic activity in the sales region.{" "}
          <u>
            By default, this is checked, and its weightage is 100%. If the user
            is in this page prior to any significant LoanProtect sales, it is
            best to leave as is
          </u>
          .
        </li>
        <li>
          It is possible that the user may possess data relevant to 'expected
          LoanProtect sales' e.g. their historical volume of sales of other
          insurance products, which the user feels may modify the actual sales
          of LoanProtect.
        </li>
        <li>
          It is possible that if the user already has experience in selling
          LoanProtect, they may wish to use that experience to modify the raw
          data in (1) above. If so, as in (2), they would check the relevant
          box, assign a weightage to this kind of data and upload the data into
          the UE. The details of what form the upload ought to take, may be
          obtained from Machine Cover.
        </li>
      </ol>
      Once the settings are viewed or changed, the user can exit the relevant
      page and enter the Main Page as explained below.
    </div>

    <div className={style.contentTitle}>Main Page</div>
    <div className={style.paragraph}>
      In the Main Page, the user can enter a number of settings that constrain
      the LoanProtect-based product which they plan to design/test.
      Specifically, they can do the following:
      <br />
      <ol type="1">
        <li>
          <u>Which States&nbsp;</u>
          -&nbsp;select which states this product will be sold in. This has
          obvious implications for market size and risk profile. Machine Cover
          has created a number of ‘canned’ sets of states for convenience, or
          the user can select their own combination.
        </li>
        <li>
          <u>Trigger Duration&nbsp;</u>
          -&nbsp;this allows you to select whether the ‘pain’ of an economic
          plunge endures for 2 months (the default) or for 1 month. We don’t
          advise changing this from the default, since that might lead to an
          overlap with nat.-cat. events.
        </li>
        <li>
          <u>Risk Threshold&nbsp;</u>
          -&nbsp;allows the user to set a ‘risk threshold’ i.e. how much worse
          should a given square be, compared to the nation before it can
          trigger. The user is welcome to try various risk thresholds. We
          suggest going to market with risk thresholds of between 25% and 40% -
          lower and you might pay out too much, higher and you inhibit purchases
          of the product.
        </li>
        <li>
          <u>Trigger Propensity&nbsp;</u>
          -&nbsp;it turns out that the more volatile the economic activity in a
          square is on a regular basis, the higher is its propensity to trigger.
          This field allows the user to exclude squares from sales of
          LoanProtect (and thus triggering and payout) by specifically excluding
          those squares that are most likely to trigger. The default is to
          exclude the ‘worst’ 10% of squares. It may be best to leave as is
          unless you have a strong reason to change.
        </li>
        <li>
          <u>Exclusions&nbsp;</u>
          -&nbsp;Whether to impose exclusions. Please note that this only refers
          to exclusions that can be automatically determined - you can certainly
          impose manually determined exclusions in the policy form (although we
          discourage it). We’ve included two exclusions of which one is enabled
          by default:
          <ol type="a">
            <li>
              The declaration by the WHO of a global pandemic and its lifting
              (disabled by default).
            </li>
            <li>
              The declaration of a statewide lockdown by governmental
              authorities, and its lifting (enabled by default). The exclusion
              applies only to the state to which the lockdown is relevant. No
              LoanProtect may trigger in a given state for any reason while an
              exclusion is in effect, relevant to that state.
            </li>
          </ol>
        </li>
      </ol>
      Once the user sets the values above, they can click IMPOSE CAPS, which
      leads to the next section:
    </div>

    <div className={style.contentTitle}>Impose Caps Dialog</div>
    <div className={style.paragraph}>
      By the time the user has got to this dialog, they’ve already defined the
      desired states, the risk threshold and exclusions if any. This dialog
      allows them to further refine the definition of their product before doing
      a ‘what-if’ with all these settings.
      <br />
      <br />
      In this dialog, the user is expected to enter the following:
      <ol type="1">
        <li>
          <u>Annual Premium per Policy&nbsp;</u>
          -&nbsp;how much will be charged the policyholder each year for this
          policy? The number is expressed in thousands of dollars (so for
          instance a $1,700 annual premium would be expressed as 1.7).
        </li>
        <li>
          <u>Payout if Policy Triggers&nbsp;</u>
          -&nbsp;how much is paid out to the policyholder if the policy
          ‘triggers’ and any other conditions are met? The number is expressed
          in thousands of dollars.
        </li>
        <li>
          <u>How many Policies Sold?&nbsp;</u>
          -&nbsp;how many policies are expected to be sold for the purposes of
          this simulation? The number is expressed in thousands.
        </li>
        <li>
          <u>Payout Risk&nbsp;</u>
          -&nbsp;what percentage of primary payout risk is contemplated? This
          should be 100 unless the simulation is being conducted by one of
          multiple parties that are sharing the primary risk. Expressed as a
          percentage.
        </li>
        <li>
          <u>Primary Payout Capacity&nbsp;</u>
          -&nbsp;What is the total amount of primary payout capacity to be
          deployed? In other words, if an economic cell triggers, this is the
          pool of money out of which payouts are to be made, until it is
          exhausted (and reinsurance kicks in).
        </li>
        <li>
          <u>Total Risk Cap&nbsp;</u>
          -&nbsp;What is the amount of Total Risk Cap to be deployed? Strictly
          speaking this entry is not relevant since all payouts that occur past
          the point where primary payout capacity is exhausted, come out of
          reinsurance. However, it may be informative to see how sharply the
          probability of payout via reinsurance falls, because this engine
          supplies the probability that reinsurance is dipped into at all, as
          well as the probability that the amount ‘dedicated’ to reinsurance, is
          used up.
        </li>
        <li>
          <u>Low Cap&nbsp;</u>
          -&nbsp;the distribution in the left part of this dialog shows the
          range of economic activity in LoanProtect-qualified squares, i.e. low
          activity at the ‘low’ end and high activity at the ‘high’ end. This
          field asks the user to enter the maximum risk that can be taken on at
          the low end of economic activity i.e. in the square where we might
          expect a low number of LoanProtect-product buyers. The system
          automatically suggests a value for this field.
        </li>
        <li>
          <u>High Cap&nbsp;</u>
          -&nbsp;this uses the same logic as the prior point and is the maximum
          risk that can be taken on at the high end of economic activity, i.e.
          in the square where we might expect a large number of
          LoanProtect-product buyers. The system automatically suggests a value
          for this field.
        </li>
        <li>
          <u>Number of hot-zones&nbsp;</u>
          -&nbsp;A hot-zone is defined as a 9x9 square region (for example a
          city and its inner suburbs) within which at least 10 squares are
          ‘LoanProtect-qualified’ (LoanProtect policies can be sold into these
          squares). The idea of imposing caps on hot-zones is that an economic
          plunge may not confine itself to one or two squares – it may well
          affect a whole city. Thus, we want to make sure that we haven’t
          concentrated too much risk in any single region. We accomplish this by
          defining how many hot-zones we want to consider (by counting downward
          using economic activity) in this field and then imposing a cap on all
          such hot-zones in the next field.
        </li>
        <li>
          <u>Maximum risk per hot-zone&nbsp;</u>
          -&nbsp;The maximum amount of risk this user wants to take on in any
          single hot-zone.
        </li>
      </ol>
      Once the user has entered all these values, they can click on any of 4
      buttons: DATA DOWNLOAD, QUICK ANALYSIS, DEEP ANALYSIS and MAKE PRODUCT.
    </div>

    <div className={style.contentTitle}>DATA DOWNLOAD</div>
    <div className={style.paragraph}>
      This allows the user to download the data that is used to make the run
      defined by the current settings (so for example if the product is to be
      sold in only 2 states then the downloaded data only pertains to those
      states).
    </div>

    <div className={style.contentTitle}>QUICK ANALYSIS</div>
    <div className={style.paragraph}>
      The main purpose of this button is to allow the user to get answers that
      are directionally what they want to see, in a couple of minutes or less.
      It uses 100 Monte Carlo simulations and the results are conveyed to the
      user via email. The results show the expected loss ratio, the probability
      that the entire defined primary payout capacity is used up (and thus
      reinsurance is dipped into), as well as the probability that the entire
      defined reinsurance amount is used up.
      <br />
      <br />
      In addition, the following is also available in the email via a link:
      <ul>
        <li>
          A listing of all the squares and the number of triggers in each square
          during the months under consideration.
        </li>
        <li>
          A curve of the Maximum Expected Payout vs Probability of Occurrence as
          computed during the simulation.
        </li>
        <li>
          A tabular representation of the Maximum Expected Payout vs Probability
          of Occurrence as computed during the simulation.
        </li>
      </ul>
    </div>

    <div className={style.contentTitle}>DEEP ANALYSIS</div>
    <div className={style.paragraph}>
      This is similar to QUICK ANALYSIS except it runs for a lot longer
      (generally 30 minutes or less) and it uses 1000 Monte Carlo analyses. This
      is suggested as being more detailed/accurate prior to making the final
      decision to take a product to market.
    </div>

    <div className={style.contentTitle}>MAKE PRODUCT</div>
    <div className={style.paragraph}>
      If a user has concluded that it makes economic sense to take this product
      to market, then this user can use the Machine Cover Platform to manage the
      sales, service and payouts of this product. The first step in this process
      is to click on MAKE PRODUCT so that the settings in the current run of the
      Underwriting Engine are captured as a JSON file which can then be expanded
      upon by Machine Cover’s Product Definition System. For a complete
      description of Machine Cover’s Product Definition System, please look at
      the file entitled <b>Machine Cover Product Definition Template V3.0</b>.
      At the moment the use of this button is confined to Machine Cover
      employees.
    </div>

    <div className={style.contentTitle}>
      Things to keep in mind before defining & selling
    </div>
    <div className={style.paragraph}>
      <ul>
        <li>
          <u>Fraud & Moral Hazard&nbsp;</u>
          -&nbsp;It is difficult to commit fraud around LoanProtect since the
          measure of economic activity we use – billions of card transactions –
          cannot be ‘fiddled with’ by buyer or seller.
        </li>
        <li>
          <u>Adverse Selection&nbsp;</u>
          -&nbsp;The only way to experience adverse selection in LoanProtect is
          where some squares have a higher inherent propensity to trigger than
          others. We’ve already analyzed the historical data for this
          possibility and eliminated such squares. In addition, since the design
          of LoanProtect anyway prevents triggers resulting from predictable
          drops, that issue also goes away.
        </li>
        <li>
          <u>Basis Risk&nbsp;</u>
          -&nbsp;conventionally defined as the difference between loss as
          modeled and loss as actually experienced. In the case of LoanProtect,
          the notion of basis risk is irrelevant since the policyholder receives
          a defined amount when a defined event occurs, and the payout is
          unrelated to loss as experienced by the policyholder.
        </li>
        <li>
          <u>Total Risk&nbsp;</u>
          -&nbsp;This is usual for any insurance product – the ability to impose
          a cap upon total risk taken on.
        </li>
        <li>
          <u>Concentration/Aggregation Risk&nbsp;</u>
          -&nbsp;Finally, we deal with the class of risk that seems most
          perilous to underwriters – the fear that too much risk will be
          concentrated in one area and if that area happens to trigger, the
          losses may be calamitous. This is dealt with through the ability to
          impose risk caps on a per-square basis as well as on a multi-square
          (hot-zone) basis.
        </li>
      </ul>
    </div>

    {/* <div className={style.contentTitle}>Regulatory concerns</div>
    <div className={style.paragraph}>
      Generally speaking, any insurance product involves an ‘event’ (some type
      of adverse and fortuitous occurrence that raises the possibility of a
      claim/payout) and a ‘loss’ (some type of loss suffered by the policyholder
      as a result of this event). In the case of LoanProtect, the ‘event’
      certainly exists, but the notion of ‘loss’ in the indemnity sense does not
      exist. However, in order to regulate LoanProtect as an insurance product,
      it may be necessary for the policyholder to attest to the fact that they
      did suffer a loss as a result of the ‘event’, (that they are unable to
      repay their loan).
      <br />
      <br />
      We suggest trying to file this product as non-admitted in view of the fact
      that (a) profitability is high enough to allow for premium taxes (b) the
      regulatory load is a lot lighter and (c) no competing product is offered
      to the best of our knowledge.
    </div> */}
  </>
);

export default LoanProtectUserGuide;
