import Footer from "components/global/Footer/Footer";
import Header from "components/global/Header/Header";
import FloatLabel from "components/global/Input/FloatingLabel";
import ProductTile from "components/global/ProductTile";
import Overview from "components/Overview/Overview";
import Dashboard from "components/Dashboard/Dashboard";
import UserGuide from "components/UserGuide/UserGuide";
import Loading from "components/global/Loading/Loading";
import Products from "components/Products/Products";
import ProductSetting from "components/ProductSetting/ProductSetting";
import SettingsPack from "components/SettingsPack/SettingsPack";

export default {
  Footer,
  Header,
  FloatLabel,
  Overview,
  Dashboard,
  UserGuide,
  Loading,
  Products,
  ProductSetting,
  ProductTile,
  SettingsPack,
};
