import React, { useEffect, useState } from "react";
import {
  Layout,
  List,
  Typography,
  Spin,
  message,
  Pagination,
  Button,
  Modal,
} from "antd";
import { RightOutlined, LeftOutlined } from "@ant-design/icons";
import connectionInstance from "api_services/connection";
import { useAuthContext } from "contexts/AuthContext";
import { SettingsPackLogo } from "assets/index";
import { useHistory } from "react-router-dom";
import StateRiskCapsModal from "../StateRiskCapsModal/StateRiskCapsModal";

const { Sider, Content } = Layout;
const { Title, Text } = Typography;

const SettingsPack = () => {
  const userContext = useAuthContext();
  const { user } = userContext;
  const [loading, setLoading] = useState(false);
  const [packs, setPacks] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize] = useState(10);
  const history = useHistory();
  const [selectedPack, setSelectedPack] = useState(null);
  const [showStateModal, setShowStateModal] = useState(false);

  const fetchPacks = async () => {
    try {
      setLoading(true);
      const response = await connectionInstance.get(
        "/probability/setting_packs",
        {
          params: {
            user_email: user.email,
            page,
            page_size: pageSize,
          },
        }
      );

      if (response && response.data) {
        const packsData = response.data.data || [];
        setPacks(packsData);
        // Use the total_count from the response if available.
        // If total_count is not provided, fallback to the number of items loaded.
        setTotal(response.data.total_count || packsData.length);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error("Could not fetch settings packs. Please try again.");
    }
  };

  useEffect(() => {
    fetchPacks();
  }, [page]);

  const handleSelectPack = async (packItem) => {
    try {
      setLoading(true);
      const detailRes = await connectionInstance.get(
        `/probability/setting_packs/${packItem.id}`,
        {
          params: {
            user_email: user.email,
          },
        }
      );
      if (detailRes && detailRes.data) {
        localStorage.removeItem("riskCaps");
        setSelectedPack(detailRes.data);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      localStorage.removeItem("riskCaps");
      message.error("Could not fetch the pack details. Please try again.");
    }
  };

  const handleDeletePack = async (packId) => {
    try {
      setLoading(true);
      await connectionInstance.delete(`/probability/setting_packs/${packId}`, {
        params: {
          user_email: user.email,
        },
      });
      message.success("Successfully deleted the settings pack.");
      // After delete, refresh the list & clear selected pack
      fetchPacks();
      setSelectedPack(null);
    } catch (error) {
      message.error("Could not delete pack. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      className="main-div-parent"
      style={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        marginTop: "20px",
        marginLeft: "10%",
      }}
    >
      <div className="child-1" style={{ position: "relative" }}>
        <Spin spinning={loading} tip="Loading settings packs...">
          {/* Left Panel: Settings packs list */}
          <div
            style={{
              background: "#fff",
              borderRight: "1px solid #F0F0F0",
              minHeight: "85vh",
              position: "relative",
            }}
          >
            <div style={{ padding: "16px", width: "300px" }}>
              <Title
                level={5}
                style={{
                  marginBottom: 16,
                  fontSize: "20px",
                  color: "#90A7B9",
                  cursor: "pointer",
                }}
                onClick={() => window.location.reload()}
              >
                Settings Packs
              </Title>
              <List
                style={{ marginBottom: "40px" }}
                dataSource={packs}
                renderItem={(item) => (
                  <List.Item
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      cursor: "pointer",
                      borderBottom: "1px solid #F0F0F0",
                      fontSize: "16px",
                      fontWeight: "400",
                      backgroundColor:
                        selectedPack && selectedPack.id === item.id
                          ? "#F5F5F5"
                          : "white",
                      paddingLeft: "5px",
                      paddingRight: "5px",
                    }}
                    onClick={() => handleSelectPack(item)}
                  >
                    <Text>{item.name}</Text>
                    <RightOutlined />
                  </List.Item>
                )}
              />

              {/* Pagination if you have many packs */}
              <div
                style={{
                  position: "absolute",
                  bottom: "16px",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  left: "0px",
                }}
              >
                <Pagination
                  current={page}
                  pageSize={pageSize}
                  total={total}
                  onChange={(newPage) => setPage(newPage)}
                />
              </div>
            </div>
          </div>
        </Spin>
      </div>

      <div className="child-2" style={{ width: "70%" }}>
        {/* Right Panel: Settings pack details */}
        <Layout style={{ height: "85vh" }}>
          <Content style={{ padding: "24px", background: "#fff" }}>
            {!selectedPack ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={SettingsPackLogo}
                  alt="Settings Pack Logo"
                  style={{ height: "40%", width: "40%" }}
                />
                <Title
                  level={4}
                  style={{
                    textAlign: "center",
                    maxWidth: 500,
                    marginTop: "2%",
                  }}
                >
                  Save time by using the settings packs you have
                  <br />
                  already created or click on the button below.
                </Title>
                <Button
                  style={{
                    background: "#2491E0",
                    color: "#fff",
                    border: "none",
                    marginTop: "2.5%",
                  }}
                  onClick={() => {
                    history.push("/dashboard?mode=new");
                    localStorage.removeItem("riskCaps");
                  }}
                >
                  Create New Pack
                </Button>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    borderBottom: "1px solid #D9D9D9",
                    paddingBottom: "5px",
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <Button
                      onClick={() => setSelectedPack(null)}
                      style={{ border: "none", paddingLeft: "0px" }}
                    >
                      <LeftOutlined />
                    </Button>
                    <Title
                      level={4}
                      style={{
                        fontSize: "20px",
                        fontWeight: "700",
                        marginBottom: "12px",
                        color: "#262626",
                      }}
                    >
                      {selectedPack.name}
                    </Title>
                  </div>
                  <Button
                    type="primary"
                    onClick={() => {
                      Modal.confirm({
                        title: "Are you sure you want to delete this pack?",
                        content: "This action cannot be undone.",
                        onOk: () => handleDeletePack(selectedPack.id),
                      });
                    }}
                  >
                    Delete Pack
                  </Button>
                </div>

                <div style={{ overflowY: "auto", maxHeight: "66vh" }}>
                  <div
                    style={{
                      paddingTop: "10px",
                      fontSize: "16px",
                      fontWeight: "400",
                    }}
                  >
                    <Text strong>States:</Text>{" "}
                    {selectedPack.settings?.states.join(", ")}
                  </div>
                  <div
                    style={{
                      marginTop: 2,
                      fontSize: "16px",
                      fontWeight: "400",
                    }}
                  >
                    <Text strong>Risk Threshold:</Text>{" "}
                    {selectedPack.settings?.trigger_threshold !== undefined
                      ? `${selectedPack.settings?.trigger_threshold}%`
                      : "N/A"}
                  </div>
                  <div
                    style={{
                      marginTop: 2,
                      fontSize: "16px",
                      fontWeight: "400",
                    }}
                  >
                    <Text strong>Trigger Duration:</Text>{" "}
                    {selectedPack.settings?.num_ongoing_months !== undefined
                      ? `${selectedPack.settings?.num_ongoing_months} months`
                      : "N/A"}
                  </div>
                  {/* <div
                    style={{
                      marginTop: 2,
                      fontSize: "16px",
                      fontWeight: "400",
                    }}
                  >
                    <Text strong>Geo Basis:</Text>{" "}
                    {selectedPack.settings?.geoBasis}
                  </div> */}
                  {/* <div
                    style={{
                      marginTop: 2,
                      fontSize: "16px",
                      fontWeight: "400",
                    }}
                  >
                    <Text strong>Number of DP-qualified squares:</Text>{" "}
                    {selectedPack.settings?.numberOfOutlocks}
                  </div> */}
                  <div
                    style={{
                      marginTop: 2,
                      fontSize: "16px",
                      fontWeight: "400",
                    }}
                  >
                    <Text strong>
                      Annual premium per policy (Thousand USD):
                    </Text>{" "}
                    {selectedPack.settings?.premiumPerOutlock !== undefined
                      ? `${selectedPack.settings?.premiumPerOutlock}`
                      : "N/A"}
                  </div>
                  <div
                    style={{
                      marginTop: 2,
                      fontSize: "16px",
                      fontWeight: "400",
                    }}
                  >
                    <Text strong>Payout if policy trigger (Thousand USD):</Text>{" "}
                    {selectedPack.settings?.meanPayout !== undefined
                      ? `${selectedPack.settings?.meanPayout}`
                      : "N/A"}
                  </div>
                  <div
                    style={{
                      marginTop: 2,
                      fontSize: "16px",
                      fontWeight: "400",
                    }}
                  >
                    <Text strong>Policies sold (Thousand Policies):</Text>{" "}
                    {selectedPack.settings?.numberOfOutlocks !== undefined
                      ? `${selectedPack.settings?.numberOfOutlocks}`
                      : "N/A"}
                  </div>
                  {/* <div
                    style={{
                      marginTop: 2,
                      fontSize: "16px",
                      fontWeight: "400",
                    }}
                  >
                    <Text strong>Payout risk:</Text>{" "}
                    {selectedPack.settings?.userRiskPercent}
                    {"%"}
                  </div> */}
                  {/* <div
                    style={{
                      marginTop: 2,
                      fontSize: "16px",
                      fontWeight: "400",
                    }}
                  >
                    <Text strong>Analysis By:</Text>{" "}
                    {selectedPack.settings?.pricingOption}
                  </div> */}
                  {/* <div
                    style={{
                      marginTop: 2,
                      fontSize: "16px",
                      fontWeight: "400",
                    }}
                  >
                    <Text strong>Trigger Drop:</Text>{" "}
                    {selectedPack.settings?.triggerDrop}
                    {"%"}
                  </div> */}

                  <Title
                    level={4}
                    style={{
                      fontSize: "20px",
                      fontWeight: "700",
                      marginTop: 4,
                      color: "#262626",
                    }}
                  >
                    Risk Caps
                  </Title>
                  <Title
                    level={3}
                    style={{
                      fontSize: "16px",
                      fontWeight: "700",
                      marginTop: 2,
                      color: "#262626",
                    }}
                  >
                    Global
                  </Title>

                  <div
                    style={{
                      marginTop: 2,
                      fontSize: "16px",
                      fontWeight: "400",
                    }}
                  >
                    <Text strong>Primary Payout Capacity (Million USD):</Text>{" "}
                    {selectedPack.settings?.userRiskInMillions !== undefined
                      ? `${selectedPack.settings?.userRiskInMillions}`
                      : "N/A"}
                  </div>

                  <div
                    style={{
                      marginTop: 2,
                      fontSize: "16px",
                      fontWeight: "400",
                    }}
                  >
                    <Text strong>Total Risk Cap (Million USD):</Text>{" "}
                    {selectedPack.settings?.totalRiskCapInMilllions !==
                    undefined
                      ? `${selectedPack.settings?.totalRiskCapInMilllions}`
                      : "N/A"}
                  </div>

                  <div
                    style={{
                      marginTop: 2,
                      fontSize: "16px",
                      fontWeight: "400",
                    }}
                  >
                    <Text strong>Probability for PML:</Text>{" "}
                    {selectedPack.settings?.probabilityForPML !== undefined
                      ? `${selectedPack.settings?.probabilityForPML}%`
                      : "N/A"}
                  </div>

                  <Title
                    level={3}
                    style={{
                      fontSize: "17px",
                      fontWeight: "700",
                      marginTop: 4,
                      marginBottom: 2,
                      color: "#262626",
                    }}
                  >
                    State
                  </Title>

                  <div
                    style={{
                      marginTop: 2,
                      fontSize: "16px",
                      fontWeight: "400",
                    }}
                  >
                    <Text strong>Imposed caps:</Text>
                    <Button onClick={() => setShowStateModal(true)} type="link">
                      View Details
                    </Button>
                  </div>

                  <Title
                    level={3}
                    style={{
                      fontSize: "17px",
                      fontWeight: "700",
                      marginTop: 4,
                      marginBottom: 2,
                      color: "#262626",
                    }}
                  >
                    Local(per square)
                  </Title>

                  {/* <div
                    style={{
                      marginTop: 2,
                      fontSize: "16px",
                      fontWeight: "400",
                    }}
                  >
                    <Text strong>Low cap:</Text> {selectedPack.settings?.lowCap}
                    {" thousand USD"}
                  </div> */}

                  <div
                    style={{
                      marginTop: 2,
                      fontSize: "16px",
                      fontWeight: "400",
                    }}
                  >
                    <Text strong>High cap (Thousand USD):</Text>{" "}
                    {selectedPack.settings?.highCap !== undefined
                      ? selectedPack.settings?.highCap
                      : "N/A"}
                  </div>

                  <Title
                    level={3}
                    style={{
                      fontSize: "17px",
                      fontWeight: "700",
                      marginTop: 4,
                      marginBottom: 2,
                      color: "#262626",
                    }}
                  >
                    Hot-Zone (per9x9 squares)
                  </Title>

                  <div
                    style={{
                      marginTop: 2,
                      fontSize: "16px",
                      fontWeight: "400",
                    }}
                  >
                    <Text strong>Number of Hot-Zones:</Text>{" "}
                    {selectedPack.settings?.hotZoneCount !== undefined
                      ? selectedPack.settings?.hotZoneCount
                      : "N/A"}
                  </div>

                  <div
                    style={{
                      marginTop: 2,
                      fontSize: "16px",
                      fontWeight: "400",
                    }}
                  >
                    <Text strong>Maximum risk per hot zone (Million USD):</Text>{" "}
                    {selectedPack.settings?.maxRiskPerHotzoneInMillions !==
                    undefined
                      ? selectedPack.settings?.maxRiskPerHotzoneInMillions
                      : "N/A"}
                  </div>
                </div>

                <div
                  style={{
                    borderTop: "1px solid #D9D9D9",
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: "2.5%",
                  }}
                >
                  {/* <Button
                    style={{
                      background: "#2491E0",
                      color: "#fff",
                      border: "none",
                      marginTop: "2.5%",
                    }}
                    onClick={() => {
                      history.push("/dashboard?mode=new");
                      localStorage.removeItem("riskCaps");
                    }}
                  >
                    Create New Pack
                  </Button> */}
                  <Button
                    style={{
                      background: "#2491E0",
                      color: "#fff",
                      border: "none",
                      marginTop: "2.5%",
                      padding: "0px 50px",
                    }}
                    onClick={() =>
                      history.push(`/dashboard?mode=edit&id=${selectedPack.id}`)
                    }
                  >
                    Apply
                  </Button>
                </div>
              </div>
            )}
          </Content>
        </Layout>
      </div>
      {/* StateRiskCapsModal usage */}
      {selectedPack && (
        <StateRiskCapsModal
          visible={showStateModal}
          onClose={() => setShowStateModal(false)}
          maxRiskPerStateInMillions={
            selectedPack?.settings?.maxRiskPerStateInMillions || {}
          }
        />
      )}
    </div>
  );
};

export default SettingsPack;
