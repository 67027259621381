import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "antd";
import { SelectedProduct } from "api_services/localstorage";
import style from "./userGuide.module.scss";
import OutLockUserGuide from "./OutLockUserGuide";
import LoanProtectUserGuide from "./LoanProtectUserGuide";

const UserGuide = () => {
  const selectedProduct = SelectedProduct.getProduct();
  const [content, setContent] = useState(<></>);
  const history = useHistory();

  useEffect(() => {
    switch (selectedProduct) {
      case "BudgetLock":
        setContent(<OutLockUserGuide />);
        break;

      case "Disruption Parachute":
        setContent(<OutLockUserGuide />);
        break;

      case "LoanProtect":
        setContent(<LoanProtectUserGuide />);
        break;

      default:
        break;
    }
  }, []);

  const handleButtonClick = () => {
    history.push("/dashboard");
  };

  return (
    <div className={style.UserGuideContainer}>
      <div className={style.title}>User Guide</div>
      <div className={style.userGuideTextContent}>{content}</div>
      <div className={style.nextButtonSection}>
        <Button
          className={style.nextButton}
          type="primary"
          onClick={handleButtonClick}
        >
          NEXT
        </Button>
      </div>
    </div>
  );
};

export default UserGuide;
