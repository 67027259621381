import React, { useEffect, useState } from "react";
import { Typography, Button, Spin } from "antd";
import { useHistory } from "react-router-dom";
import connectionInstance from "api_services/connection";
import { AuthToken, SelectedProduct } from "api_services/localstorage";
import style from "./overview.module.scss";
import OutlockOverview from "./OutlockOverview";
import BudgetlockOverview from "./BudgetlockOverview";
import LoanProtectOverview from "./LoanProtectOverview";

const { Text } = Typography;

const Overview = () => {
  const [hideContent, sethideContent] = useState(true);
  const selectedProduct = SelectedProduct.getProduct();
  const history = useHistory();
  const [content, setContent] = useState(<></>);

  useEffect(() => {
    async function checkSessionStatus() {
      const response = await connectionInstance
        .get(`${process.env.REACT_APP_API_URL}/probability/request`, {
          params: {
            states: "CA",
            trigger_threshold: -33,
            geo_basis: "square",
          },
        })
        .then((res) => {
          if (res) {
            sethideContent(false);
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 401) {
              AuthToken.removeToken();
              window.location = `${process.env.REACT_APP_AWS_DOMAIN}/logout?client_id=${process.env.REACT_APP_AWS_COGNITO_CLIENT_ID}&response_type=${process.env.REACT_APP_AWS_RESPONSE_TYPE}&redirect_uri=${process.env.REACT_APP_AWS_REDIRECT_URI}`;
            } else {
              sethideContent(false);
            }
          } else {
            sethideContent(false);
          }
        });
    }
    checkSessionStatus();
  });

  useEffect(() => {
    switch (selectedProduct) {
      case "BudgetLock":
        setContent(<BudgetlockOverview />);
        break;

      case "Disruption Parachute":
        setContent(<OutlockOverview />);
        break;

      case "LoanProtect":
        setContent(<LoanProtectOverview />);
        break;

      default:
        break;
    }
  }, []);

  const handleButtonClick = () => {
    history.push("/settings-pack");
  };

  return (
    <div className={style.overviewContainer}>
      {!hideContent ? (
        <div>
          <div className={style.overviewSection}>{content}</div>
          <div className={style.buttonSection}>
            <div className={style.buttonContent}>
              <Button
                className={style.backButton}
                type="primary"
                onClick={() => history.goBack()}
              >
                BACK
              </Button>
              <Button
                className={style.launchButton}
                type="primary"
                onClick={() => handleButtonClick()}
              >
                NEXT
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <Spin
          spinning={hideContent}
          style={{ position: "fixed", top: "40%", left: "50%" }}
        />
      )}
    </div>
  );
};

export default Overview;
