import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import {
  Typography,
  Input,
  Button,
  Row,
  Col,
  Checkbox,
  Tooltip,
  message,
  Modal,
  Popover,
  Spin,
  Select,
  notification,
} from "antd";
import {
  QuestionCircleFilled,
  CloseOutlined,
  InfoCircleFilled,
  LeftOutlined,
} from "@ant-design/icons";
import {
  STATES,
  STATES_BY_POPULATION,
  STATES_BY_TRANSACTION,
  MAINLAND_STATES,
  EXCLUSION_LIST,
  PRODUCT_JSON,
} from "constants/Constants";
import connectionInstance from "api_services/connection";
import Components from "components";
import { Line } from "react-chartjs-2";
import { SelectedProduct } from "api_services/localstorage";
import moment from "moment";
import { useAuthContext } from "../../contexts/AuthContext";
import style from "./dashboard.module.scss";
import DataAnalysisInput from "./DataAnalysisInput";
import StateRiskCapsModal from "../StateRiskCapsModal/StateRiskCapsModal";

const { Text } = Typography;
const { Option } = Select;

export const getThresholdData = async function (
  triggerRequestId,
  setshowLoading
) {
  try {
    const triggerDataResponse = await connectionInstance.get(
      `${process.env.REACT_APP_API_URL}/probability/status/${triggerRequestId}`
    );
    if (triggerDataResponse && triggerDataResponse.data) {
      return triggerDataResponse.data;
    }
    return false;
  } catch (error) {
    setshowLoading(false);
    if (error.response.status !== 401) {
      if (error.response && error.response.data) {
        message.error(
          (typeof error.response.data.error_string === "string" &&
            error.response.data.error_string) ||
            (typeof error.response.data.message === "string" &&
              error.response.data.message) ||
            (typeof error.response.data.errorMsg === "string" &&
              error.response.data.errorMsg) ||
            (typeof error.response.data.error_message === "string" &&
              error.response.data.error_message) ||
            "Sorry! We are unable to fetch probable trigger analysis now. Please try after some time."
        );
      } else {
        message.error(
          "Sorry! We are unable to fetch probable trigger analysis now. Please try after some time."
        );
      }
    }
    return false;
  }
};

const Dashboard = () => {
  const userContext = useAuthContext();
  const { user } = userContext;
  const selectedProduct = SelectedProduct.getProduct();
  const [thresholdValue, setthresholdValue] = useState();
  const [thresholdRangeError, setthresholdRangeError] = useState(false);
  const [disableRunButton, setdisableRunButton] = useState(false);
  // const [probabilityValue, setprobabilityValue] = useState(0);
  // const [expectedPayoutValue, setexpectedPayoutValue] = useState(0);
  const [radioButtonValue, setradioButtonValue] = useState("all");
  const [showStateSection, setshowStateSection] = useState(false);
  const [showSelectedStateLabel, setshowSelectedStateLabel] = useState(false);
  const [selectedStates, setselectedStates] = useState("");
  const [stateListArray, setstateListArray] = useState(STATES);
  const [countSelectedState, setcountSelectedState] = useState(51);
  const [disableDownload, setdisableDownload] = useState(false);
  const [downloadURL, setdownloadURL] = useState();
  const [showProbabilityTrigger, setshowProbabilityTrigger] = useState(false);
  const [showLoading, setshowLoading] = useState(false);
  const [showSpinner, setshowSpinner] = useState(false);
  const [showButtonSpinner, setshowButtonSpinner] = useState({
    quickAnalysis: false,
    deepAnalysis: false,
  });
  const [intervalData, setintervalData] = useState({
    intervalValue: null,
    intervalDuration: 3000,
    timeoutValue: null,
  });
  const [disableWhileLoading, setdisableWhileLoading] = useState(false);
  const [exclusionStatus, setexclusionStatus] = useState(true);
  const [analysisType, setanalysisType] = useState(
    selectedProduct == "BudgetLock" ? "city" : "square"
  );
  const [
    frequencyDistributionModalVisible,
    setfrequencyDistributionModalVisible,
  ] = useState(false);
  const [confirmationModalVisible, setconfirmationModalVisible] =
    useState(null);
  const [totalRisk, settotalRisk] = useState("");
  const [reinsurerRisk, setreinsurerRisk] = useState("");
  const [probabilityForPML, setProbabilityForPML] = useState("");
  const [stateRisk, setstateRisk] = useState("No Caps");
  const [averagePayout, setaveragePayout] = useState("");
  const [premiumPerOutlock, setpremiumPerOutlock] = useState("");
  const [percentageOfRisk, setpercentageOfRisk] = useState("100");
  const [maxSale, setmaxSale] = useState("");
  const [lowCapFactor, setlowCapFactor] = useState("");
  const [highCapFactor, sethighCapFactor] = useState("");
  const [numberOfHotZones, setnumberOfHotZones] = useState("0");
  const [hotZonesTotalRisk, sethotZonesTotalRisk] = useState("0");
  const [disableFrequencyDistribution, setdisableFrequencyDistribution] =
    useState(true);
  // const [disableSavePackBtn, setdisableSavePackBtn] = useState(true);
  const [triggerFileName, settriggerFileName] = useState(null);
  const [zipCodeModalVisible, setZipCodeModalVisible] = useState(false);
  const [popover, setpopover] = useState({
    totalRisk: false,
    reinsurerRisk: false,
    probabilityForPML: false,
    stateRisk: false,
    payout: false,
    premium: false,
    percentageOfRisk: false,
    maxSale: false,
    lowCapFactor: false,
    highCapFactor: false,
    hotZonesTotalRisk: false,
    numberOfHotZones: false,
    reset: false,
    run: false,
    deepAnalysis: false,
    quickAnalysis: false,
    downloadData: false,
    WHO: false,
    STW: false,
  });
  // const [alertMessage, setalertMessage] = useState(null);
  const [statsFile, setstatsFile] = useState(null);
  const [economicActivity, seteconomicActivity] = useState([]);
  const [squares, setsquares] = useState([]);
  const [exclusionCheckBox, setexclusionCheckBox] = useState({
    "WHO pandemic declaration": false,
    "State-wide lockdowns": true,
  });
  const [requestId, setrequestId] = useState(null);
  const [numberOfOutlockWorthySquare, setnumberOfOutlockWorthySquare] =
    useState(null);
  const [triggerDurationValue, settriggerDurationValue] = useState("2 months");
  const [activeExclusionPanel, setactiveExclusionPanel] = useState(1);
  const [lowerEconomicPercentile, setlowerEconomicPercentile] = useState(0);
  const [upperEconomicPercentile, setupperEconomicPercentile] = useState(100);
  const [triggerDrop, settriggerDrop] = useState(10);
  const [triggerDropError, settriggerDropError] = useState(false);
  const [policyNumberModalVisible, setPolicyNumberModalVisible] =
    useState(null);
  const [makeProductModalVisible, setMakeProductModalVisible] = useState(false);
  const [product, setProduct] = useState({
    name: "",
    internalName: "",
    desc: "",
  });
  const [pricingValue, setPricingValue] = useState("national");
  const [viewMode, setViewMode] = useState("default");
  const routerLocation = useLocation();
  const history = useHistory();
  const [packData, setpackData] = useState();
  const [originalPackSettings, setOriginalPackSettings] = useState(null);
  const [isDirty, setIsDirty] = useState(false); // if user changed anything from the original
  const [saveSettingsModalVisible, setSaveSettingsModalVisible] =
    useState(false);
  const [newPackName, setNewPackName] = useState("");
  const [maxRiskPerStateInMillions, setMaxRiskPerStateInMillions] = useState(
    {}
  );

  // Helper function to check if an object is empty
  const isEmptyObject = (obj) => {
    return Object.keys(obj).length === 0;
  };

  function deepCompare(obj1, obj2) {
    // Check if both inputs are objects
    if (
      typeof obj1 !== "object" ||
      typeof obj2 !== "object" ||
      obj1 === null ||
      obj2 === null
    ) {
      return obj1 === obj2;
    }

    // Get keys of both objects
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    // Check if the number of keys is the same
    if (keys1.length !== keys2.length) {
      return false;
    }

    // Iterate through keys of obj1
    for (let key of keys1) {
      // Check if the key exists in obj2
      if (!obj2.hasOwnProperty(key)) {
        return false;
      }

      // Recursively compare nested objects or arrays
      if (typeof obj1[key] === "object" && obj1[key] !== null) {
        if (!deepCompare(obj1[key], obj2[key])) {
          return false;
        }
      } else {
        // Compare primitive values
        if (obj1[key] !== obj2[key]) {
          return false;
        }
      }
    }

    return true;
  }

  // Retrieve maxRiskPerStateInMillions from localStorage
  const getMaxRiskPerStateInMillionsFromStorage = () => {
    const storedRiskCaps = localStorage.getItem("riskCaps");
    return storedRiskCaps ? JSON.parse(storedRiskCaps) : {};
  };

  // Get maxRiskPerStateInMillions from localStorage or use the default from the state
  const maxRiskPerStateInMillionsFromStorage =
    getMaxRiskPerStateInMillionsFromStorage();

  // Consolidate current settings into an object- you have following settings modal data object
  const buildCurrentSettings = () => {
    let maxRisk = {};
    if (stateRisk === "No Caps") {
      maxRisk = {};
    } else {
      if (!isEmptyObject(maxRiskPerStateInMillionsFromStorage)) {
        maxRisk = maxRiskPerStateInMillionsFromStorage;
      } else if (
        packData?.settings?.maxRiskPerStateInMillions &&
        !isEmptyObject(packData?.settings?.maxRiskPerStateInMillions)
      ) {
        maxRisk = packData.settings.maxRiskPerStateInMillions;
      }
    }
    // Build the settings object, conditionally assigning each key.
    const settings = {
      trigger_threshold: thresholdValue
        ? parseFloat(thresholdValue)
        : undefined,
      geoBasis: analysisType || undefined,
      num_ongoing_months: triggerDurationValue
        ? triggerDurationValue === "1 month"
          ? 1
          : 2
        : undefined,
      triggerDrop: triggerDrop ? parseFloat(triggerDrop) : undefined,
      probabilityForPML: probabilityForPML
        ? parseFloat(probabilityForPML)
        : undefined,
      userRiskInMillions: totalRisk ? parseFloat(totalRisk) : undefined,
      totalRiskCapInMilllions: reinsurerRisk
        ? parseFloat(reinsurerRisk)
        : undefined,
      meanPayout: averagePayout ? parseFloat(averagePayout) : undefined,
      premiumPerOutlock: premiumPerOutlock
        ? parseFloat(premiumPerOutlock)
        : undefined,
      userRiskPercent: percentageOfRisk
        ? parseFloat(percentageOfRisk)
        : undefined,
      numberOfOutlocks: maxSale ? parseFloat(maxSale) : undefined,
      lowCap: lowCapFactor ? parseFloat(lowCapFactor) : undefined,
      highCap: highCapFactor ? parseFloat(highCapFactor) : undefined,
      hotZoneCount: numberOfHotZones
        ? parseInt(numberOfHotZones, 10)
        : undefined,
      maxRiskPerHotzoneInMillions: hotZonesTotalRisk
        ? parseFloat(hotZonesTotalRisk)
        : undefined,
      pricingOption: pricingValue || undefined,
      exclusionsList: "STW", // always sent
      stateRisk: stateRisk || undefined,
      maxRiskPerStateInMillions: maxRisk, // either {} or the caps object
      states: stateListArray.flatMap((region) =>
        region.states.filter((st) => st.status).map((st) => st.value)
      ),
    };

    // Remove keys with undefined, null, or empty-string values.
    const cleanedSettings = Object.keys(settings).reduce((acc, key) => {
      const value = settings[key];
      // Include if value is not undefined, not null, and not an empty string (after trimming if it's a string).
      if (
        value !== undefined &&
        value !== null &&
        !(typeof value === "string" && value.trim() === "")
      ) {
        acc[key] = value;
      }
      return acc;
    }, {});
    return cleanedSettings;
  };

  // Helper function to validate risk caps if stateRisk is "Impose Caps"
  const validateRiskCaps = () => {
    if (stateRisk === "Impose Caps") {
      let riskCaps = {};
      // First try to get the risk caps from localStorage; if not available, use what is in packData.
      if (localStorage.getItem("riskCaps")) {
        riskCaps = JSON.parse(localStorage.getItem("riskCaps"));
      } else if (packData?.settings?.maxRiskPerStateInMillions) {
        riskCaps = packData.settings.maxRiskPerStateInMillions;
      }
      if (isEmptyObject(riskCaps)) {
        message.error(
          "Please fill in Imposed Caps State Values before proceeding."
        );
        return false;
      }
      // Check that every state's value is a number greater than 0.
      for (let key in riskCaps) {
        const value = parseFloat(riskCaps[key]);
        if (isNaN(value) || value <= 0) {
          message.error(
            "Please fill in all state risk cap values with numbers greater than 0."
          );
          return false;
        }
      }
    }
    return true;
  };

  const checkIfDirty = () => {
    const currentSettings = buildCurrentSettings();
    setIsDirty(!deepCompare(currentSettings, originalPackSettings));
  };

  useEffect(() => {
    checkIfDirty();
  }, [
    thresholdValue,
    analysisType,
    triggerDurationValue,
    triggerDrop,
    probabilityForPML,
    premiumPerOutlock,
    totalRisk,
    reinsurerRisk,
    averagePayout,
    percentageOfRisk,
    maxSale,
    lowCapFactor,
    highCapFactor,
    numberOfHotZones,
    hotZonesTotalRisk,
    pricingValue,
    stateListArray,
    maxRiskPerStateInMillionsFromStorage,
  ]);

  useEffect(() => {
    // Convert the comma‐separated selectedStates string into an array.
    const selectedStatesArray = selectedStates
      ? selectedStates
          .split(",")
          .map((s) => s.trim())
          .filter(Boolean)
      : [];

    // If available (in edit mode), get the preloaded risk caps from packData.
    // Otherwise, default to an empty object.
    const preloadedRiskCaps =
      (packData &&
        packData.settings &&
        packData.settings.maxRiskPerStateInMillions) ||
      {};

    // Retrieve any previously stored risk caps from localStorage.
    const storedRiskCaps = JSON.parse(localStorage.getItem("riskCaps")) || {};

    // Build a new risk caps object for only the currently selected states.
    // Priority order: Preloaded values > stored values > default to an empty string.
    const updatedRiskCaps = {};
    selectedStatesArray.forEach((stateCode) => {
      updatedRiskCaps[stateCode] = preloadedRiskCaps.hasOwnProperty(stateCode)
        ? preloadedRiskCaps[stateCode]
        : storedRiskCaps[stateCode] || "";
    });

    // Update localStorage and the component state.
    localStorage.setItem("riskCaps", JSON.stringify(updatedRiskCaps));
    setMaxRiskPerStateInMillions(updatedRiskCaps);
  }, [selectedStates, packData]);

  // The handleSelectPack fetches the pack by ID, then sets relevant states
  const handleSelectPack = async (packId) => {
    try {
      setdisableWhileLoading(true);
      const detailRes = await connectionInstance.get(
        `/probability/setting_packs/${packId}`,
        {
          params: {
            user_email: user.email,
          },
        }
      );
      if (detailRes && detailRes.data) {
        const pack = detailRes.data;
        const settings = pack.settings;
        setpackData(pack);
        setOriginalPackSettings(settings);

        // Now we set the corresponding states on the Dashboard
        // based on the pack's settings
        if (settings.trigger_threshold !== undefined) {
          setthresholdValue(settings.trigger_threshold.toString());
        }
        if (settings.geoBasis) {
          setanalysisType(settings.geoBasis);
        }
        if (settings.num_ongoing_months) {
          settriggerDurationValue(
            settings.num_ongoing_months === 1 ? "1 month" : "2 months"
          );
        }
        if (settings.triggerDrop !== undefined) {
          settriggerDrop(settings.triggerDrop.toString());
        }
        if (settings.probabilityForPML !== undefined) {
          setProbabilityForPML(settings.probabilityForPML.toString());
        }
        if (settings.userRiskInMillions !== undefined) {
          settotalRisk(settings.userRiskInMillions.toString());
        }
        if (settings.totalRiskCapInMilllions !== undefined) {
          setreinsurerRisk(settings.totalRiskCapInMilllions.toString());
        }
        if (settings.meanPayout !== undefined) {
          setaveragePayout(settings.meanPayout.toString());
        }
        if (settings.premiumPerOutlock !== undefined) {
          setpremiumPerOutlock(settings.premiumPerOutlock.toString());
        }
        if (settings.userRiskPercent !== undefined) {
          setpercentageOfRisk(settings.userRiskPercent.toString());
        }
        if (settings.numberOfOutlocks !== undefined) {
          setmaxSale(settings.numberOfOutlocks.toString());
        }
        if (settings.lowCap !== undefined) {
          setlowCapFactor(settings.lowCap.toString());
        }
        if (settings.highCap !== undefined) {
          sethighCapFactor(settings.highCap.toString());
        }
        if (settings.hotZoneCount !== undefined) {
          setnumberOfHotZones(settings.hotZoneCount.toString());
        }
        if (settings.maxRiskPerHotzoneInMillions !== undefined) {
          sethotZonesTotalRisk(settings.maxRiskPerHotzoneInMillions.toString());
        }
        if (settings.pricingOption) {
          setPricingValue(settings.pricingOption);
        }
        if (settings.stateRisk !== undefined) {
          setstateRisk(settings.stateRisk);
        }
        if (settings.states && Array.isArray(settings.states)) {
          const newStateListArray = JSON.parse(JSON.stringify(STATES));
          newStateListArray.forEach((regionBlock) => {
            regionBlock.states.forEach((st) => {
              st.status = settings.states.includes(st.value);
            });
          });
          setstateListArray(newStateListArray);

          const combined = settings.states.join(",");
          setselectedStates(combined);
          setcountSelectedState(settings.states.length);
        }

        if (settings.lowerEconomicPercentile !== undefined) {
          setlowerEconomicPercentile(settings.lowerEconomicPercentile);
        }
        if (settings.upperEconomicPercentile !== undefined) {
          setupperEconomicPercentile(settings.upperEconomicPercentile);
        }
        if (settings.maxRiskPerStateInMillions !== undefined) {
          setMaxRiskPerStateInMillions(settings.maxRiskPerStateInMillions);
        }

        message.success("Settings pack loaded into Dashboard!");
      }
    } catch (error) {
      message.error("Could not fetch the pack details. Please try again.");
    } finally {
      setdisableWhileLoading(false);
    }
  };

  const queryParams = new URLSearchParams(routerLocation.search);
  const mode = queryParams.get("mode");

  // QUERY PARSER
  useEffect(() => {
    const queryParams = new URLSearchParams(routerLocation.search);
    const mode = queryParams.get("mode");
    const packId = queryParams.get("id");

    if (mode === "edit" && packId) {
      // Make the pack call
      handleSelectPack(packId);
    }
  }, [routerLocation.search]);

  const [renameModalVisible, setRenameModalVisible] = useState(false);
  const [editedPackName, setEditedPackName] = useState("");

  // Function called when the user clicks "Save Pack" (for both new and edit modes)
  // const saveSettingsPack = async () => {
  //   if (!validateRiskCaps()) return;

  //   const currentSettings = buildCurrentSettings();
  //   try {
  //     if (mode === "new") {
  //       // NEW MODE: simply POST a new pack.
  //       if (!newPackName) {
  //         message.error("Please provide a pack name first.");
  //         return;
  //       }
  //       await connectionInstance.post(`/probability/setting_packs`, {
  //         user_email: user.email,
  //         name: newPackName,
  //         settings: currentSettings,
  //       });
  //       message.success("Settings pack created successfully!");
  //       // localStorage.removeItem("riskCaps");
  //       setSaveSettingsModalVisible(false);
  //       // history.push("/settings-pack");
  //     } else {
  //       // EDIT MODE: open the rename modal.
  //       // (The rename modal’s handler will take care of PATCH vs. POST and duplicate errors.)
  //       setEditedPackName(packData?.name || "");
  //       setRenameModalVisible(true);
  //     }
  //   } catch (err) {
  //     // If the error is due to a duplicate name, show the error toast and leave the modal open.
  //     if (
  //       err.response &&
  //       err.response.data &&
  //       err.response.data.error_type === "DUPLICATE_NAME"
  //     ) {
  //       message.error(
  //         err.response.data.error_string ||
  //           "A settings pack with the provided name already exists. Please choose a different name."
  //       );
  //       // Do not close the modal so that the user can enter a new name.
  //     } else {
  //       message.error("Failed to save settings pack. Please try again later.");
  //       setSaveSettingsModalVisible(false);
  //       // history.push("/settings-pack");
  //     }
  //   }
  // };
  const saveSettingsPack = async () => {
    if (!validateRiskCaps()) return;

    const currentSettings = buildCurrentSettings();
    try {
      if (mode === "new") {
        if (!newPackName) {
          message.error("Please provide a pack name first.");
          return;
        }
        // If a pack has already been saved (packData exists) and the name hasn’t changed, update it.
        if (packData && packData.name === newPackName.trim()) {
          await connectionInstance.patch(
            `/probability/setting_packs/${packData.id}`,
            {
              name: newPackName,
              settings: currentSettings,
            },
            { params: { user_email: user.email } }
          );
          message.success("Settings pack updated successfully!");
        } else {
          // Otherwise, create a new pack.
          const response = await connectionInstance.post(
            `/probability/setting_packs`,
            {
              user_email: user.email,
              name: newPackName,
              settings: currentSettings,
            }
          );
          // Save the returned pack in state so subsequent saves can use PATCH.
          setpackData(response.data);
          message.success("Settings pack created successfully!");
        }
        setSaveSettingsModalVisible(false);
      } else {
        // In edit mode, open the rename modal (whose logic already handles PATCH vs POST).
        setEditedPackName(packData?.name || "");
        setRenameModalVisible(true);
      }
    } catch (err) {
      if (
        err.response &&
        err.response.data &&
        err.response.data.error_type === "DUPLICATE_NAME"
      ) {
        message.error(
          err.response.data.error_string ||
            "A settings pack with the provided name already exists. Please choose a different name."
        );
        // Leave the modal open so that the user can change the name.
      } else {
        message.error("Failed to save settings pack. Please try again later.");
        setSaveSettingsModalVisible(false);
      }
    }
  };

  // This function is called from the Rename Modal when the user clicks Save.
  const handleSaveRename = async () => {
    if (!editedPackName.trim()) {
      message.error("Pack name cannot be empty.");
      return;
    }
    if (!validateRiskCaps()) return;
    try {
      // If the user leaves the name unchanged then update the existing pack (PATCH)
      // Otherwise, if the name is changed then create a new pack (POST)
      if (editedPackName.trim() === packData.name) {
        await connectionInstance.patch(
          `/probability/setting_packs/${packData.id}`,
          {
            name: editedPackName,
            settings: buildCurrentSettings(),
          },
          { params: { user_email: user.email } }
        );
        message.success("Settings pack updated successfully!");
      } else {
        await connectionInstance.post(`/probability/setting_packs`, {
          user_email: user.email,
          name: editedPackName,
          settings: buildCurrentSettings(),
        });
        message.success("Settings pack created successfully!");
      }
      setRenameModalVisible(false);
      setSaveSettingsModalVisible(false);
      // localStorage.removeItem("riskCaps");
      // history.push("/settings-pack");
    } catch (err) {
      if (
        err.response &&
        err.response.data &&
        err.response.data.error_type === "DUPLICATE_NAME"
      ) {
        message.error(
          err.response.data.error_string ||
            "A settings pack with the provided name already exists. Please choose a different name."
        );
        // Do not close the modal so the user can modify the name.
      } else {
        message.error("Failed to update settings pack. Please try again.");
        setRenameModalVisible(false);
        setSaveSettingsModalVisible(false);
        // history.push("/settings-pack");
      }
    }
  };

  const openNotification = (desc) => {
    notification.error({
      message: "",
      description: desc,
      placement: "top",
      duration: 0,
      icon: <InfoCircleFilled style={{ color: "#ff4d4f", fontSize: "24px" }} />,
    });
  };

  useEffect(() => {
    let states = "";
    let selectedStateCount = 0;
    stateListArray.forEach((item) => {
      item.states.forEach((state) => {
        if (state.status) {
          selectedStateCount++;
          states += `${state.value},`;
        }
      });
    });
    // Remove trailing comma if exists
    if (states.endsWith(",")) {
      states = states.slice(0, -1);
    }
    setselectedStates(states);
    setcountSelectedState(selectedStateCount);

    // Enable the button if there is at least one selected state and the threshold fields are valid
    if (
      selectedStateCount > 0 &&
      thresholdValue &&
      !thresholdRangeError &&
      triggerDrop &&
      !triggerDropError
    ) {
      setdisableRunButton(false);
    } else {
      setdisableRunButton(true);
    }
  }, [
    stateListArray,
    thresholdValue,
    triggerDrop,
    thresholdRangeError,
    triggerDropError,
  ]);

  useEffect(() => {
    if (
      countSelectedState > 0 &&
      thresholdValue &&
      triggerDrop &&
      selectedStates &&
      !thresholdRangeError &&
      !triggerDropError
    ) {
      if (
        selectedProduct == "BudgetLock" &&
        !(lowerEconomicPercentile < upperEconomicPercentile)
      ) {
        setdisableRunButton(true);
      } else {
        setdisableRunButton(false);
      }
    } else {
      setdisableRunButton(true);
    }
  }, [
    countSelectedState,
    thresholdValue,
    triggerDrop,
    selectedStates,
    thresholdRangeError,
    triggerDropError,
    lowerEconomicPercentile,
    upperEconomicPercentile,
  ]);

  useEffect(() => {
    if (
      averagePayout &&
      premiumPerOutlock &&
      percentageOfRisk &&
      lowCapFactor &&
      highCapFactor &&
      triggerFileName &&
      statsFile &&
      maxSale &&
      !disableWhileLoading
    ) {
      if (
        analysisType == "square" &&
        (!hotZonesTotalRisk || !numberOfHotZones)
      ) {
        setdisableFrequencyDistribution(true);
        // setdisableSavePackBtn(true);
      } else {
        setdisableFrequencyDistribution(false);
        // setdisableSavePackBtn(false);
      }
    } else {
      setdisableFrequencyDistribution(true);
      // setdisableSavePackBtn(true);
    }
  }, [
    averagePayout,
    premiumPerOutlock,
    lowCapFactor,
    percentageOfRisk,
    highCapFactor,
    triggerFileName,
    statsFile,
    disableWhileLoading,
    hotZonesTotalRisk,
    numberOfHotZones,
    maxSale,
  ]);

  const toolTipText =
    "For example, if you set 20 in this field then:\n\n(a) If the nation's economic activity " +
    "(as measured by the data we have) rose by 4% over the measurement period, then " +
    `a ${selectedProduct} in ` +
    `a particular ${
      analysisType == "square"
        ? " square"
        : analysisType == "zip"
        ? " zip code"
        : " city"
    } would be a candidate for a ` +
    `trigger if that ${
      analysisType == "square"
        ? " square"
        : analysisType == "zip"
        ? " zip code"
        : " city"
    } dropped by 16% or worse during ` +
    "the same period.\n\n(b) If the nation's economic activity dropped by 3% over the measurement period then " +
    `a ${selectedProduct} in a particular ${
      analysisType == "square"
        ? " square"
        : analysisType == "zip"
        ? " zip code"
        : " city"
    } would be a ` +
    `candidate for a trigger if that ${
      analysisType == "square"
        ? " square"
        : analysisType == "zip"
        ? " zip code"
        : " city"
    } dropped by 23% or ` +
    "worse during the same period.\n\n(c)...and so on.";

  const handleThresholdValueChange = (event) => {
    setthresholdValue(event.target.value);
  };

  const handleDownloadButtonClick = async () => {
    window.location.href = downloadURL;
    setdisableDownload(true);
  };

  const handleResetButtonClick = () => {
    clearInterval(intervalData.intervalValue);
    clearTimeout(intervalData.timeoutValue);
    setintervalData((prev) => ({
      ...prev,
      intervalValue: null,
      timeoutValue: null,
    }));
    setthresholdValue();
    settriggerDrop();
    setshowStateSection(false);
    setshowProbabilityTrigger(false);
    setTimeout(() => {
      if (!showLoading) {
        setshowProbabilityTrigger(false);
      }
    }, 1000);
    STATES.map((item) => {
      item.states.map((state) => {
        state.status = true;
      });
    });
    setstateListArray([...STATES]);
    let selectedState = 0;
    STATES.map((item) => {
      item.states.map((state) => {
        if (state.status) {
          selectedState++;
        }
      });
    });
    setcountSelectedState(selectedState);
    setradioButtonValue("all");
    setdisableWhileLoading(false);

    setexclusionStatus(true);
    setdisableRunButton(true);
    setthresholdRangeError(false);
    setdisableDownload(false);
    setanalysisType(selectedProduct == "BudgetLock" ? "city" : "square");
    settriggerFileName(null);
    setstatsFile(null);
    setexclusionCheckBox((prev) => ({
      ...prev,
      "WHO pandemic declaration": false,
      "State-wide lockdowns": true,
    }));
    settriggerDurationValue("2 months");
    setactiveExclusionPanel(1);
    const blankArray = [];
    seteconomicActivity([...blankArray]);
    setsquares([...blankArray]);
    setlowerEconomicPercentile(0);
    setupperEconomicPercentile(100);
    settriggerDrop(10);
    settriggerDropError(false);
    settotalRisk("");
    setreinsurerRisk("");
    setProbabilityForPML("");
    setstateRisk("No Caps");
    setaveragePayout("");
    setpercentageOfRisk("100");
    setpremiumPerOutlock("");
    setmaxSale("");
    setlowCapFactor("");
    sethighCapFactor("");
    if (analysisType == "square") {
      setnumberOfHotZones("0");
      sethotZonesTotalRisk("0");
    } else {
      setnumberOfHotZones("0");
      sethotZonesTotalRisk("0");
    }
    setnumberOfOutlockWorthySquare(null);
  };

  const handleFrequencyDistribution = async (quickRun) => {
    if (!validateRiskCaps()) return;
    try {
      setdisableWhileLoading(true);
      setdisableDownload(true);
      if (quickRun) {
        setshowButtonSpinner((prev) => ({
          ...prev,
          quickAnalysis: true,
        }));
      } else {
        setshowButtonSpinner((prev) => ({
          ...prev,
          deepAnalysis: true,
        }));
      }

      const requestParams = {
        userName: user.name,
        userEmail: user.email,
        triggerFile: triggerFileName,
        userRiskInMillions: totalRisk || 0,
        totalRiskCapInMilllions: reinsurerRisk || 0,
        probabilityForPML: probabilityForPML || 0,
        stateRisk: stateRisk || {},
        userRiskPercent: percentageOfRisk,
        meanPayout: averagePayout,
        numberOfOutlocks: maxSale,
        premiumPerOutlock,
        lowCap: lowCapFactor,
        highCap: highCapFactor,
        hotZoneCount: analysisType == "zip" ? 0 : Number(numberOfHotZones),
        maxRiskPerHotzoneInMillions:
          analysisType == "zip" ? 0 : Number(hotZonesTotalRisk),
        geoBasis: analysisType,
        pricingOption: pricingValue,
        statsFile,
        quickRun: quickRun ? "1" : "0",
        requestId,
        exclusionsList:
          Object.keys(exclusionCheckBox).filter(
            (k) => exclusionCheckBox[k] === true
          ).length > 0
            ? getExclusionList(false)
            : "None",
        product: selectedProduct,
        maxRiskPerStateInMillions:
          stateRisk === "No Caps"
            ? {}
            : localStorage.getItem("riskCaps") ||
              packData?.settings?.maxRiskPerStateInMillions ||
              {},
        settingPack: newPackName || packData?.name || "",
      };
      const response = await connectionInstance.get(
        `${process.env.REACT_APP_API_URL}/probability/frequency_distribution`,
        {
          params: requestParams,
        }
      );
      if (response && response.data && response.data.processed) {
        if (quickRun) {
          setshowButtonSpinner((prev) => ({
            ...prev,
            quickAnalysis: false,
          }));
        } else {
          setshowButtonSpinner((prev) => ({
            ...prev,
            deepAnalysis: false,
          }));
        }
        setdisableWhileLoading(false);
        setdisableDownload(false);
        setconfirmationModalVisible(quickRun ? "quick" : "deep");
      }
    } catch (error) {
      if (quickRun) {
        setshowButtonSpinner((prev) => ({
          ...prev,
          quickAnalysis: false,
        }));
      } else {
        setshowButtonSpinner((prev) => ({
          ...prev,
          deepAnalysis: false,
        }));
      }
      setdisableDownload(false);
      setdisableWhileLoading(false);
      if (
        error &&
        error.response &&
        error.response.status &&
        error.response.status !== 401
      ) {
        if (error.response.data) {
          message.error(
            (typeof error.response.data.error_string === "string" &&
              error.response.data.error_string) ||
              (typeof error.response.data.message === "string" &&
                error.response.data.message) ||
              (typeof error.response.data.errorMsg === "string" &&
                error.response.data.errorMsg) ||
              (typeof error.response.data.error_message === "string" &&
                error.response.data.error_message) ||
              "Sorry! We are unable to record your request now. Please try after some time."
          );
        } else {
          message.error(
            "Sorry! We are unable to record your request now. Please try after some time."
          );
        }
      } else {
        message.error(
          "Sorry! We are unable to record your request now. Please try after some time."
        );
      }
    }
  };

  const selectAllStates = (region, statesInRegion) => {
    setradioButtonValue("user-preference");
    setdisableRunButton(true);
    // Instead of mapping over the imported STATES, map over stateListArray
    const updatedList = stateListArray.map((regionBlock) => {
      if (region && regionBlock.region !== region) {
        return regionBlock;
      }
      return {
        ...regionBlock,
        states: regionBlock.states.map((st) => ({ ...st, status: true })),
      };
    });
    setstateListArray(updatedList);

    // Recalculate selected states from updatedList…
    let selectedStateCount = 0;
    let statesString = "";
    updatedList.forEach((regionBlock) => {
      regionBlock.states.forEach((st) => {
        if (st.status) {
          selectedStateCount++;
          statesString += `${st.value},`;
        }
      });
    });
    if (statesString.endsWith(",")) {
      statesString = statesString.slice(0, -1);
    }
    setselectedStates(statesString);
    setcountSelectedState(selectedStateCount);
  };

  const clearAllStates = (region, statesInRegion) => {
    setradioButtonValue("user-preference");
    setdisableRunButton(true);
    const updatedList = stateListArray.map((regionBlock) => {
      if (region && regionBlock.region !== region) {
        return regionBlock;
      }
      return {
        ...regionBlock,
        states: regionBlock.states.map((st) => ({ ...st, status: false })),
      };
    });
    setstateListArray(updatedList);

    // Recalculate selected states
    let selectedStateCount = 0;
    let statesString = "";
    updatedList.forEach((regionBlock) => {
      regionBlock.states.forEach((st) => {
        if (st.status) {
          selectedStateCount++;
          statesString += `${st.value},`;
        }
      });
    });
    if (statesString.endsWith(",")) {
      statesString = statesString.slice(0, -1);
    }
    setselectedStates(statesString);
    setcountSelectedState(selectedStateCount);
  };

  const onChangeCheckbox = (e, changedState) => {
    setradioButtonValue("user-preference");
    setdisableRunButton(true);

    // Make a deep copy of the current stateListArray and update only the changed state.
    const updatedList = stateListArray.map((region) => ({
      ...region,
      states: region.states.map((st) =>
        st.value === changedState.value
          ? { ...st, status: e.target.checked }
          : st
      ),
    }));
    setstateListArray(updatedList);

    // Recalculate the selected states from updatedList.
    let selectedStateCount = 0;
    let statesString = "";
    updatedList.forEach((region) => {
      region.states.forEach((st) => {
        if (st.status) {
          selectedStateCount++;
          statesString += `${st.value},`;
        }
      });
    });
    if (statesString.endsWith(",")) {
      statesString = statesString.slice(0, -1);
    }
    setselectedStates(statesString);
    setcountSelectedState(selectedStateCount);
    if (
      selectedStateCount > 0 &&
      thresholdValue &&
      !thresholdRangeError &&
      triggerDrop &&
      !triggerDropError
    ) {
      setdisableRunButton(false);
    } else {
      setdisableRunButton(true);
    }
    setdisableDownload(true);
  };

  const handleThresholdInput = async (evt) => {
    if (
      evt.target.value.toString().indexOf(".") === 0 &&
      evt.target.value.toString().split(".")[1] &&
      evt.target.value.toString().split(".")[1].length > 2
    ) {
      evt.target.value = (Math.floor(evt.target.value * 100) / 100).toFixed(2);
      setthresholdValue(evt.target.value);
    }
    if (evt.target.value === "") {
      setdisableRunButton(true);
    } else if (evt.target.value > 50) {
      setthresholdRangeError(true);
      setdisableRunButton(true);
    } else if (evt.target.value < 0.01) {
      setthresholdRangeError(true);
      setdisableRunButton(true);
    } else {
      setthresholdRangeError(false);
    }
  };

  const handleKeyDown = (evt) => {
    if (
      evt.key.toLowerCase() === "backspace" ||
      evt.key.toLowerCase() === "." ||
      evt.key.toLowerCase() === "tab"
    ) {
      return;
    }
    if (isNaN(evt.key)) {
      evt.preventDefault();
    }
    if (
      evt.target.value.toString().split(".")[1] &&
      evt.target.value.toString().split(".")[1].length > 1
    ) {
      evt.preventDefault();
    }
    if (
      !evt.target.value.toString().split(".")[1] &&
      evt.target.value.toString().length > 3
    ) {
      evt.preventDefault();
    }
  };

  const showAllSelectedState = () => {
    let states = ` ${selectedStates}`.slice(1);
    states = states.replace(/,/g, ", ");
    return states;
  };

  const getExclusionList = (shortText) => {
    let values = "";
    Object.keys(exclusionCheckBox)
      .filter((k) => exclusionCheckBox[k] === true)
      .map((item, index) => {
        let exclusionValues = "";
        if (shortText) {
          exclusionValues += EXCLUSION_LIST[item];
        } else {
          exclusionValues += item;
        }

        if (
          !(
            Number(
              Object.keys(exclusionCheckBox).filter(
                (k) => exclusionCheckBox[k] === true
              ).length
            ) -
              1 ==
            index
          )
        ) {
          exclusionValues += shortText ? "," : ", ";
        }
        values += exclusionValues;
      });
    return values;
  };

  const analyzeData = async () => {
    const queryParams = new URLSearchParams(routerLocation.search);
    const mode = queryParams.get("mode");
    if (mode === "new") {
      settotalRisk("");
      setreinsurerRisk("");
      setProbabilityForPML("");
      // setstateRisk("");
      setaveragePayout("");
      setpercentageOfRisk("100");
      setpremiumPerOutlock("");
      setmaxSale("");
      setlowCapFactor("");
      sethighCapFactor("");
      if (analysisType == "square") {
        setnumberOfHotZones("0");
        sethotZonesTotalRisk("0");
      } else {
        setnumberOfHotZones("0");
        sethotZonesTotalRisk("0");
      }
    }
    setdownloadURL(null);
    setdisableDownload(true);
    try {
      setdisableWhileLoading(true);
      setshowSpinner(true);
      const response = await connectionInstance.get(
        `${process.env.REACT_APP_API_URL}/probability/static_frequency_distribution`,
        {
          params: {
            geo_basis: analysisType,
            pricingOption: pricingValue,
            states: selectedStates,
            lowerEconomicPercentile:
              selectedProduct == "BudgetLock" ? lowerEconomicPercentile : null,
            upperEconomicPercentile:
              selectedProduct == "BudgetLock" ? upperEconomicPercentile : null,
          },
        }
      );

      if (response && response.data) {
        const xAxis = [];
        const yAxis = [];
        response.data.graph_data.map((item) => {
          if (analysisType == "square") {
            xAxis.push(item["Percentile of Squares"]);
          } else if (analysisType == "zip") {
            xAxis.push(item["Percentile of Zip Codes"]);
          } else {
            xAxis.push(item["Percentile of city_state"]);
          }
          yAxis.push(item["Percentage of economic activity"]);
        });

        setsquares([...xAxis]);
        seteconomicActivity([...yAxis]);
        setstatsFile(response.data.csv_file_name);

        const triggerRequestResponse = await connectionInstance.get(
          `${process.env.REACT_APP_API_URL}/probability/request`,
          {
            params: {
              states: selectedStates,
              trigger_threshold: Number(thresholdValue) * -1,
              triggerDrop: Number(triggerDrop) / 100,
              exclusions_list:
                Object.keys(exclusionCheckBox).filter(
                  (k) => exclusionCheckBox[k] === true
                ).length > 0
                  ? getExclusionList(true)
                  : null,
              geo_basis: analysisType,
              pricingOption: pricingValue,
              num_ongoing_months: triggerDurationValue == "1 month" ? 1 : 2,
              lowerEconomicPercentile:
                selectedProduct == "BudgetLock"
                  ? lowerEconomicPercentile
                  : null,
              upperEconomicPercentile:
                selectedProduct == "BudgetLock"
                  ? upperEconomicPercentile
                  : null,
            },
          }
        );

        if (
          triggerRequestResponse &&
          triggerRequestResponse.data &&
          triggerRequestResponse.data.id
        ) {
          setrequestId(triggerRequestResponse.data.id);

          const interval = setInterval(async () => {
            const thresholdData = await getThresholdData(
              triggerRequestResponse.data.id,
              setshowLoading
            );
            if (thresholdData) {
              settriggerFileName(thresholdData.file_name);
              setdownloadURL(thresholdData.download_link);
              setnumberOfOutlockWorthySquare(
                thresholdData.outlock_worthy_entities
              );
              setdisableDownload(false);
              setshowSpinner(false);
              if (thresholdData.file_name) {
                clearInterval(interval);
                setdisableWhileLoading(false);
                clearTimeout(timeoutInfo);
                // setfrequencyDistributionModalVisible(true);
                setViewMode("frequency");
              } else {
                setshowSpinner(true);
                setdisableDownload(true);
              }
            } else {
              clearInterval(interval);
              setshowProbabilityTrigger(false);
              setshowSpinner(false);
              setdisableRunButton(false);
              setdisableWhileLoading(false);
              clearTimeout(timeoutInfo);
              setdisableDownload(true);
            }
          }, intervalData.intervalDuration);
          setintervalData((prev) => ({
            ...prev,
            intervalValue: interval,
          }));
          let timeoutInfo = setTimeout(() => {
            clearInterval(interval);
            setdisableWhileLoading(false);
            setshowSpinner(false);
            message.error(
              "Sorry! We are unable to perform analysis now. Please try after some time."
            );
            setdisableRunButton(false);
            setTimeout(() => {
              setshowSpinner(false);
            }, 1000);
          }, 120000);
          setintervalData((prev) => ({
            ...prev,
            timeoutValue: timeoutInfo,
          }));
        }
      }

      if (Number(countSelectedState) !== 51) {
        let allStates = "";
        STATES.map((item) => {
          item.states.map((state) => {
            allStates += `${state.value},`;
          });
        });
        allStates = allStates.substring(0, allStates.length - 1);

        const response = await connectionInstance.get(
          `${process.env.REACT_APP_API_URL}/probability/request`,
          {
            params: {
              states: allStates,
              trigger_threshold: Number(thresholdValue) * -1,
              triggerDrop: Number(triggerDrop) / 100,
              exclusions_list:
                Object.keys(exclusionCheckBox).filter(
                  (k) => exclusionCheckBox[k] === true
                ).length > 0
                  ? getExclusionList(true)
                  : null,
              geo_basis: analysisType,
              pricingOption: pricingValue,
              num_ongoing_months: triggerDurationValue == "1 month" ? 1 : 2,
              lowerEconomicPercentile:
                selectedProduct == "BudgetLock"
                  ? lowerEconomicPercentile
                  : null,
              upperEconomicPercentile:
                selectedProduct == "BudgetLock"
                  ? upperEconomicPercentile
                  : null,
            },
          }
        );

        if (response) {
          console.log("Response with all states received.", response);
        }
      }
    } catch (error) {
      setshowSpinner(false);
      setdisableWhileLoading(false);
      if (
        error &&
        error.response &&
        error.response.status &&
        error.response.status !== 401
      ) {
        if (error.response && error.response.data) {
          message.error(
            (typeof error.response.data.error_string === "string" &&
              error.response.data.error_string) ||
              (typeof error.response.data.message === "string" &&
                error.response.data.message) ||
              (typeof error.response.data.errorMsg === "string" &&
                error.response.data.errorMsg) ||
              (typeof error.response.data.error_message === "string" &&
                error.response.data.error_message) ||
              "Sorry! We are unable to perform analysis now. Please try after some time."
          );
        } else {
          message.error(
            "Sorry! We are unable to perform analysis now. Please try after some time."
          );
        }
      }
    }
  };

  const handleNumberOfPolicy = () => {
    if (Number(maxSale) > 1000000) {
      setmaxSale(1000000);
    }
    setPolicyNumberModalVisible(null);
  };

  const makeProduct = async () => {
    const exclusionArr = [];
    const states = [];
    Object.keys(exclusionCheckBox)
      .filter((k) => exclusionCheckBox[k] === true)
      .map((item) => {
        exclusionArr.push({ value: item });
      });

    stateListArray.map((item) => {
      item.states.map((state) => {
        if (state.status) {
          delete state.status;
          delete state.population;
          delete state.square;
          states.push(state);
        }
      });
    });

    const downloadJSON = PRODUCT_JSON(
      product.name,
      selectedProduct,
      triggerDrop.toString(),
      Number(thresholdValue),
      triggerDurationValue[0],
      states,
      Number(highCapFactor),
      Number(lowCapFactor),
      Number(averagePayout * maxSale),
      Number(maxSale),
      numberOfHotZones,
      Number(hotZonesTotalRisk),
      averagePayout,
      exclusionArr,
      moment().format("YYYY-MM-DDTHH:mm:ss"),
      moment().add(13, "months").endOf("month").format("YYYY-MM-DDTHH:mm:ss"),
      moment().add(2, "months").startOf("month").format("YYYY-MM-DDTHH:mm:ss")
    );
    const handle = await window.showSaveFilePicker({
      suggestedName: `${product.name}.json`,
      types: [
        {
          accept: { "application/json": [".json"] },
        },
      ],
    });
    const jsonString = JSON.stringify(downloadJSON, null, 4);
    const blob = new Blob([jsonString]);

    const writableStream = await handle.createWritable();
    await writableStream.write(blob);
    await writableStream.close();

    setTimeout(() => {
      message.success("Download Successful!");
      setMakeProductModalVisible(false);
    }, 1000);
  };

  const renderDataAnalysisInput = (readOnly) => (
    <DataAnalysisInput
      isReadOnly={readOnly}
      totalRisk={totalRisk}
      reinsurerRisk={reinsurerRisk}
      probabilityForPML={probabilityForPML}
      stateRisk={stateRisk}
      averagePayout={averagePayout}
      percentageOfRisk={percentageOfRisk}
      maxSale={maxSale}
      lowCapFactor={lowCapFactor}
      highCapFactor={highCapFactor}
      hotZonesTotalRisk={hotZonesTotalRisk}
      numberOfHotZones={numberOfHotZones}
      premiumPerOutlock={premiumPerOutlock}
      settotalRisk={settotalRisk}
      setreinsurerRisk={setreinsurerRisk}
      setProbabilityForPML={setProbabilityForPML}
      setstateRisk={setstateRisk}
      setaveragePayout={setaveragePayout}
      setpercentageOfRisk={setpercentageOfRisk}
      setmaxSale={setmaxSale}
      setlowCapFactor={setlowCapFactor}
      sethighCapFactor={sethighCapFactor}
      sethotZonesTotalRisk={sethotZonesTotalRisk}
      setnumberOfHotZones={setnumberOfHotZones}
      setpremiumPerOutlock={setpremiumPerOutlock}
      setPolicyNumberModalVisible={setPolicyNumberModalVisible}
      selectedProduct={selectedProduct}
      popover={popover}
      setpopover={setpopover}
      analysisType={analysisType}
      openNotification={openNotification}
      notification={notification}
      showAllSelectedState={showAllSelectedState()}
      maxRiskPerStateInMillions={
        packData?.settings?.maxRiskPerStateInMillions || {}
      }
    />
  );

  const handleConfirmationModalOk = () => {
    setconfirmationModalVisible(null);
    // openSaveSettingsModal(); // Trigger Save Modal
  };

  // -------------- FREQUENCY DISTRIBUTION "PAGE" CODE --------------
  const renderFrequencyDistributionPage = () => {
    return (
      <div
        className="frequencyDistributionPage"
        style={{
          padding: "20px",
          backgroundColor: "#FFF",
          marginTop: "1%",
        }}
      >
        <div style={{ display: "flex" }}>
          {/* LEFT COLUMN: read-only summary */}
          <div
            className="readonlySection"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <div className="greyBorder">
              <div
                className="modalTitle"
                style={{
                  color: "#90A7B9",
                  fontSize: "24px",
                  marginBottom: "12px",
                }}
              >
                <Button
                  key="back"
                  style={{
                    paddingLeft: "0px",
                    paddingRight: "8px",
                    border: "none",
                    fontSize: "20px",
                  }}
                  disabled={disableWhileLoading}
                  onClick={() => {
                    setViewMode("default");
                  }}
                >
                  <LeftOutlined />
                </Button>
                You have the following settings:
              </div>
              <div
                className="optionSection"
                style={
                  Number(countSelectedState) > 28 &&
                  selectedProduct === "BudgetLock"
                    ? { gap: "5px" }
                    : { gap: "10px" }
                }
              >
                <div style={{ display: "flex" }}>
                  <strong>States:&nbsp;&nbsp;</strong>
                  <div style={{ width: "390px" }}>{showAllSelectedState()}</div>
                </div>
                <div style={{ display: "flex" }}>
                  <strong>Risk Threshold:&nbsp;&nbsp;</strong>
                  <div>{thresholdValue}%</div>
                </div>
                <div style={{ display: "flex" }}>
                  <strong>Trigger Duration:&nbsp;&nbsp;</strong>
                  <div>{triggerDurationValue}</div>
                </div>
                {selectedProduct === "BudgetLock" && (
                  <>
                    <div style={{ display: "flex" }}>
                      <strong>Lower Economic Percentile:&nbsp;&nbsp;</strong>
                      <div>{lowerEconomicPercentile}</div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <strong>Upper Economic Percentile:&nbsp;&nbsp;</strong>
                      <div>{upperEconomicPercentile}</div>
                    </div>
                  </>
                )}
                <div style={{ display: "flex" }}>
                  <strong>
                    Number of {selectedProduct}-qualified
                    {analysisType === "square"
                      ? " squares"
                      : analysisType === "zip"
                      ? " zip codes"
                      : " cities"}
                    :&nbsp;&nbsp;
                  </strong>
                  <div>{numberOfOutlockWorthySquare}</div>
                </div>
              </div>
              <div className="imageSection" style={{ marginTop: "5%" }}>
                <Line
                  options={{
                    maintainAspectRatio: false,
                    title: {
                      display: true,
                      text: `Distribution of economic activity across${
                        analysisType === "square"
                          ? " Squares"
                          : analysisType === "zip"
                          ? " Zip Codes"
                          : " Cities"
                      }`,
                    },
                    responsive: true,
                    animation: { duration: 2000 },
                    legend: { display: false },
                    tooltips: {
                      titleFontSize: 12,
                      bodyFontColor: "#969696",
                      borderColor: "#F55A70",
                      bodyFontSize: 14,
                      displayColors: false,
                    },
                    scales: {
                      xAxes: [
                        {
                          gridLines: { display: false },
                          ticks: {
                            fontSize: 14,
                            fontColor: "#9B93BB",
                            fontWeight: 600,
                            maxRotation: 0,
                            minRotation: 0,
                            autoSkip: true,
                            maxTicksLimit: 6,
                          },
                          scaleLabel: {
                            display: true,
                            labelString: `% of ${
                              analysisType === "square"
                                ? "Squares"
                                : analysisType === "zip"
                                ? "Zip Codes"
                                : "Cities"
                            }`,
                            fontColor: "#262626",
                            fontSize: 12,
                            padding: 0,
                          },
                        },
                      ],
                      yAxes: [
                        {
                          id: "A",
                          display: true,
                          gridLines: { display: true },
                          ticks: {
                            min: 0,
                            max: 100,
                            fontSize: 14,
                            fontColor: "#9B93BB",
                            fontWeight: 600,
                            maxTicksLimit: 10,
                          },
                          scaleLabel: {
                            display: true,
                            labelString: `% of max. economic activity per ${
                              analysisType === "zip"
                                ? "Zip code"
                                : analysisType.charAt(0).toUpperCase() +
                                  analysisType.slice(1)
                            }`,
                            fontColor: "#262626",
                            fontSize: 12,
                            padding: 0,
                          },
                        },
                      ],
                    },
                  }}
                  data={{
                    labels: squares,
                    datasets: [
                      {
                        label: `% of max. economic activity per ${
                          analysisType === "zip"
                            ? "zip code"
                            : analysisType.charAt(0).toUpperCase() +
                              analysisType.slice(1)
                        }`,
                        data: economicActivity,
                        fill: true,
                        backgroundColor: "#00a8e1",
                        borderColor: "#00a8e1",
                        pointRadius: 0,
                        pointHoverRadius: 0,
                        pointHitRadius: 20,
                      },
                    ],
                  }}
                  height={300}
                />
              </div>
            </div>
            <div
              className="modalFooter"
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: "8px",
                marginBottom: "-0.19rem",
              }}
            >
              <Button
                type="primary"
                onClick={() => {
                  Modal.confirm({
                    title: "Are you sure you want to exit?",
                    onOk: () => {
                      history.push("/settings-pack");
                      STATES.map((item) => {
                        item.states.map((state) => {
                          state.status = true;
                        });
                      });
                      setstateListArray([...STATES]);
                      let selectedState = 0;
                      STATES.map((item) => {
                        item.states.map((state) => {
                          if (state.status) {
                            selectedState++;
                          }
                        });
                      });
                    },
                  });
                }}
              >
                SETTINGS PACKS
              </Button>
              <Button
                disabled={disableDownload}
                className={style.downloadButton}
                onClick={() => handleDownloadButtonClick()}
                type="primary"
                style={{ marginRight: "2px" }}
              >
                DATA DOWNLOAD
              </Button>
              <Popover
                content={
                  <Text type="secondary">
                    Download the data that was used to arrive at the trigger
                    frequency, i.e. the actual triggers on a
                    {analysisType === "square"
                      ? " square-by-square"
                      : analysisType === "zip"
                      ? " zipcode-by-zipcode"
                      : " city-by-city"}
                    , month-by-month basis.
                  </Text>
                }
                title={
                  <div style={{ width: "100%", display: "flex" }}>
                    DATA DOWNLOAD
                    <span style={{ position: "absolute", right: "23px" }}>
                      <CloseOutlined
                        style={{ color: "rgba(0, 0, 0, 0.45)" }}
                        onClick={() =>
                          setpopover((prev) => ({
                            ...prev,
                            downloadData: false,
                          }))
                        }
                      />
                    </span>
                  </div>
                }
                trigger="click"
                placement="topRight"
                visible={popover.downloadData}
                onVisibleChange={() =>
                  setpopover((prev) => ({
                    ...prev,
                    downloadData: !popover.downloadData,
                  }))
                }
              >
                <QuestionCircleFilled className={style.questionCircleIcon} />
              </Popover>
              <Button
                onClick={() => {
                  if (mode === "new") {
                    setSaveSettingsModalVisible(true);
                  } else {
                    saveSettingsPack();
                  }
                }}
                type="primary"
                // disabled={disableSavePackBtn}
              >
                SAVE PACK
              </Button>
            </div>
            {confirmationModalVisible && (
              // You have following setting modal popup
              <Modal
                visible={!!confirmationModalVisible}
                className="confirmationModal"
                title={
                  <div
                    style={{
                      color: "#90A7B9",
                      fontSize: "24px",
                      fontWeight: "700",
                    }}
                  >
                    You have the following settings:
                  </div>
                }
                centered
                closable={false}
                width={464}
                onOk={() => setconfirmationModalVisible(null)}
                footer={[
                  <Button
                    key="back"
                    type="primary"
                    style={{ width: "67px" }}
                    onClick={handleConfirmationModalOk}
                  >
                    OK
                  </Button>,
                ]}
              >
                <div>
                  <div style={{ display: "flex", marginBottom: "10px" }}>
                    <strong>States:&nbsp;&nbsp;</strong>
                    <div style={{ width: "318px" }}>
                      {showAllSelectedState()}
                    </div>
                  </div>
                  <div style={{ display: "flex", marginBottom: "10px" }}>
                    <strong>Risk Threshold:&nbsp;&nbsp;</strong>
                    <div>{thresholdValue}%</div>
                  </div>
                  <div style={{ display: "flex", marginBottom: "10px" }}>
                    <strong>Trigger Duration:&nbsp;&nbsp;</strong>
                    <div>{triggerDurationValue}</div>
                  </div>
                  {selectedProduct == "BudgetLock" && (
                    <>
                      <div style={{ display: "flex", marginBottom: "10px" }}>
                        <strong>Lower Economic Percentile:&nbsp;&nbsp;</strong>
                        <div>{lowerEconomicPercentile}</div>
                      </div>
                      <div style={{ display: "flex", marginBottom: "10px" }}>
                        <strong>Upper Economic Percentile:&nbsp;&nbsp;</strong>
                        <div>{upperEconomicPercentile}</div>
                      </div>
                    </>
                  )}
                  <div style={{ marginBottom: "10px" }}>
                    {renderDataAnalysisInput(true)}
                  </div>
                  <p>
                    Your {confirmationModalVisible} analysis has started and the
                    results are expected to be emailed to&nbsp;
                    {user.email} within{" "}
                    {confirmationModalVisible == "quick" ? 3 : 30} minutes or
                    less.
                  </p>
                </div>
              </Modal>
            )}
          </div>

          {/* RIGHT COLUMN: inputSection */}
          <div className="inputSection" style={{ marginLeft: "20px" }}>
            <div className="modalBody" style={{ marginBottom: "10px" }}>
              {renderDataAnalysisInput(false)}
            </div>
            <div className="modalFooter" style={{ display: "flex" }}>
              <div className="footerItem" style={{ marginRight: "8px" }}>
                <Button
                  key="submit"
                  type="primary"
                  style={{ marginRight: "2px" }}
                  disabled={disableFrequencyDistribution}
                  loading={showButtonSpinner.quickAnalysis}
                  onClick={() => handleFrequencyDistribution(true)}
                >
                  QUICK ANALYSIS
                </Button>
                <Popover
                  content={
                    <Text type="secondary">
                      Given the settings entered by you, run the Underwriting
                      Engine to get a rough 'expected value' for the frequency
                      of triggering...
                    </Text>
                  }
                  title={
                    <div style={{ width: "100%", display: "flex" }}>
                      QUICK ANALYSIS
                      <span style={{ position: "absolute", right: "23px" }}>
                        <CloseOutlined
                          style={{ color: "rgba(0, 0, 0, 0.45)" }}
                          onClick={() =>
                            setpopover((prev) => ({
                              ...prev,
                              quickAnalysis: false,
                            }))
                          }
                        />
                      </span>
                    </div>
                  }
                  trigger="click"
                  visible={popover.quickAnalysis}
                  onVisibleChange={() =>
                    setpopover((prev) => ({
                      ...prev,
                      quickAnalysis: !popover.quickAnalysis,
                    }))
                  }
                >
                  <QuestionCircleFilled className={style.questionCircleIcon} />
                </Popover>
              </div>
              <div className="footerItem" style={{ marginRight: "8px" }}>
                <Button
                  key="submit"
                  type="primary"
                  style={{ marginRight: "2px" }}
                  disabled={disableFrequencyDistribution}
                  loading={showButtonSpinner.deepAnalysis}
                  onClick={() => handleFrequencyDistribution(false)}
                >
                  DEEP ANALYSIS
                </Button>
                <Popover
                  content={
                    <Text type="secondary">
                      Given the settings entered by you, run the Underwriting
                      Engine in order to get a full frequency distribution of
                      triggers...
                    </Text>
                  }
                  title={
                    <div style={{ width: "100%", display: "flex" }}>
                      FULL ANALYSIS
                      <span style={{ position: "absolute", right: "23px" }}>
                        <CloseOutlined
                          style={{ color: "rgba(0, 0, 0, 0.45)" }}
                          onClick={() =>
                            setpopover((prev) => ({
                              ...prev,
                              deepAnalysis: false,
                            }))
                          }
                        />
                      </span>
                    </div>
                  }
                  trigger="click"
                  visible={popover.deepAnalysis}
                  onVisibleChange={() =>
                    setpopover((prev) => ({
                      ...prev,
                      deepAnalysis: !popover.deepAnalysis,
                    }))
                  }
                >
                  <QuestionCircleFilled className={style.questionCircleIcon} />
                </Popover>
              </div>
              <div className="footerItem">
                <Button
                  type="primary"
                  style={{ marginRight: "2px" }}
                  disabled={disableFrequencyDistribution}
                  onClick={() => setMakeProductModalVisible(true)}
                >
                  MAKE PRODUCT
                </Button>
              </div>
            </div>
          </div>
        </div>

        {/* Modal for new mode */}
        {mode === "new" && saveSettingsModalVisible && (
          <Modal
            visible={saveSettingsModalVisible}
            onCancel={() => {
              setSaveSettingsModalVisible(false);
              // history.push("/settings-pack");
            }}
            title="Save as a setting pack"
            footer={null}
            destroyOnClose
          >
            <p>
              Would you like to retain your settings for future use? If so,
              assign a name to this settings pack.
            </p>
            <p
              style={{
                fontSize: "14px",
                fontWeight: "700",
                marginTop: "25px",
                marginBottom: "5px",
              }}
            >
              Settings pack name
            </p>
            <Input
              value={newPackName}
              onChange={(e) => setNewPackName(e.target.value)}
              placeholder="e.g. Settings_pack_1"
            />
            <div
              style={{
                marginTop: "16px",
                textAlign: "right",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Button
                type="link"
                onClick={() => {
                  setSaveSettingsModalVisible(false);
                  // history.push("/settings-pack");
                }}
                style={{ border: "none", padding: "0px" }}
              >
                Continue without saving
              </Button>
              <Button type="primary" onClick={saveSettingsPack}>
                Save
              </Button>
            </div>
          </Modal>
        )}

        {/* Rename Modal for edit mode */}
        {mode === "edit" && renameModalVisible && (
          <Modal
            visible={renameModalVisible}
            onCancel={() => setRenameModalVisible(false)}
            title="Save Settings Pack"
            footer={null}
            destroyOnClose
          >
            <p>If you want to edit the name before saving:</p>
            <Input
              value={editedPackName}
              onChange={(e) => setEditedPackName(e.target.value)}
              placeholder="Enter new pack name"
            />
            <div style={{ marginTop: "16px", textAlign: "right" }}>
              <Button
                onClick={() => {
                  setRenameModalVisible(false);
                  // history.push("/settings-pack");
                }}
                style={{ marginRight: "8px" }}
              >
                Cancel
              </Button>
              <Button type="primary" onClick={handleSaveRename}>
                Save
              </Button>
            </div>
          </Modal>
        )}

        <Modal
          visible={makeProductModalVisible}
          title={null}
          centered
          closable={false}
          width={426}
          footer={null}
        >
          <div>
            <div style={{ width: "100%" }}>
              <Components.FloatLabel
                labelName="Product Name"
                inputName="productName"
                valueText={product.name}
              >
                <Input
                  id="productName"
                  autoComplete="off"
                  size="large"
                  style={{ height: "45px", paddingTop: "9px" }}
                  value={product?.name}
                  onChange={(e) => {
                    const { value } = e.target;
                    setProduct((prev) => ({
                      ...prev,
                      name: value,
                    }));
                  }}
                />
              </Components.FloatLabel>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Button
                key="back"
                type="link"
                style={{ padding: 0 }}
                onClick={() => {
                  setMakeProductModalVisible(false);
                  setProduct((prev) => ({
                    ...prev,
                    name: "",
                    internalName: "",
                    desc: "",
                  }));
                }}
              >
                CANCEL
              </Button>
              <Button
                type="primary"
                disabled={!product.name}
                onClick={() => makeProduct()}
              >
                MAKE PRODUCT
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    );
  };

  if (viewMode === "frequency") {
    return renderFrequencyDistributionPage();
  }

  return (
    <div className={style.dashboardContainer}>
      {/* {contextHolder} */}
      <Spin
        spinning={showSpinner && !frequencyDistributionModalVisible}
        style={{ position: "fixed", top: "20%" }}
      >
        <Modal
          visible={!!policyNumberModalVisible}
          className={style.zipCodeModal}
          title={null}
          centered
          closable={false}
          width={426}
          onOk={() => handleNumberOfPolicy()}
          footer={null}
        >
          <div className={style.zipcodeModalText}>
            <p>{policyNumberModalVisible}</p>
            <div style={{ width: "100%", textAlign: "center" }}>
              <Button
                key="back"
                style={{ width: "67px" }}
                type="primary"
                onClick={() => handleNumberOfPolicy()}
              >
                OK
              </Button>
            </div>
          </div>
        </Modal>

        <Modal
          visible={zipCodeModalVisible}
          className={style.zipCodeModal}
          title={null}
          centered
          closable={false}
          width={426}
          onOk={() => setZipCodeModalVisible(false)}
          footer={null}
        >
          <div className={style.zipcodeModalText}>
            <strong>
              You have selected analysis by zip codes. Before you proceed,
              please be aware that:
            </strong>

            <p className={style.paragraph}>
              (a) We consider a zip code to be {selectedProduct}
              -qualified (i.e. you can sell
              {selectedProduct} within that zip code) as long as that zip code
              contains at least one
              {selectedProduct}
              -qualified square.
            </p>

            <p className={style.paragraph}>
              (b) The triggering of an {selectedProduct} that is sold in a zip
              code, is determined solely by the triggering of that{" "}
              {selectedProduct} as if it had been sold in an aggregate area
              consisting of all the
              {selectedProduct}
              -qualified squares in that zip code.
            </p>

            <p className={style.paragraph}>
              (c) The User Guide refers simply to the use of {selectedProduct}
              -qualified squares and does not refer to zip codes. This is
              because squares and their analysis underlies all work in this
              Underwriting Engine, regardless of whether squares are used
              directly, or whether they are associated with their containing zip
              codes. Thus, please feel free to use 'Zip Code' wherever you see
              'Square' in the User Guide.
            </p>

            <div style={{ width: "100%", textAlign: "center" }}>
              <Button
                key="back"
                style={{ width: "67px" }}
                type="primary"
                onClick={() => setZipCodeModalVisible(false)}
              >
                OK
              </Button>
            </div>
          </div>
        </Modal>

        <div className={style.stateSection}>
          <Button
            key="back"
            style={{
              paddingLeft: "0px",
              paddingRight: "8px",
              border: "none",
              fontSize: "20px",
              backgroundColor: "transparent",
            }}
            disabled={disableWhileLoading}
            onClick={() => {
              history.push("/settings-pack");
              STATES.map((item) => {
                item.states.map((state) => {
                  state.status = true;
                });
              });
              setstateListArray([...STATES]);
              let selectedState = 0;
              STATES.map((item) => {
                item.states.map((state) => {
                  if (state.status) {
                    selectedState++;
                  }
                });
              });
            }}
          >
            <LeftOutlined />
          </Button>
          <Text className={style.title}>Select States</Text>
          <Button
            className={style.selectAll}
            type="link"
            size="small"
            onClick={() => selectAllStates(null, null)}
            disabled={disableWhileLoading}
          >
            Select All
          </Button>
          <Button
            className={style.selectAll}
            type="link"
            size="small"
            onClick={() => clearAllStates(null, null)}
            disabled={disableWhileLoading}
          >
            Clear All
          </Button>
          <div className={style.stateListContent}>
            <div className={style.stateListSection}>
              <div className={style.stateList}>
                <Row gutter={16}>
                  {stateListArray.map((item, index) => (
                    <Col
                      style={{
                        flexBasis: "20%",
                        width: "20%",
                        display: "block",
                      }}
                      key={index}
                    >
                      <div>
                        <Text style={{ fontWeight: "700" }}>{item.region}</Text>
                        <Button
                          className={style.selectAll}
                          type="link"
                          size="small"
                          onClick={() =>
                            selectAllStates(item.region, item.states)
                          }
                          disabled={disableWhileLoading}
                        >
                          Select All
                        </Button>
                        <Button
                          className={style.selectAll}
                          type="link"
                          size="small"
                          onClick={() =>
                            clearAllStates(item.region, item.states)
                          }
                          disabled={disableWhileLoading}
                        >
                          Clear All
                        </Button>
                      </div>
                      <div style={{ display: "grid", marginTop: "7px" }}>
                        {item.states.map((state) => (
                          <Checkbox
                            disabled={disableWhileLoading}
                            style={{ marginLeft: 0, marginTop: "3px" }}
                            checked={state.status}
                            key={state.value}
                            onChange={(e) => onChangeCheckbox(e, state)}
                          >
                            <Text
                              className={
                                !state.status ? style.checkBoxText : null
                              }
                            >
                              {state.display}
                              &nbsp;
                            </Text>
                          </Checkbox>
                        ))}
                      </div>
                    </Col>
                  ))}
                </Row>
              </div>

              <div style={{ padding: "16px 0" }}>
                <div className={style.separator} />
              </div>
              <div className={style.stateSectionFooter}>
                <div style={{ position: "absolute", right: "0" }}>
                  <Text className={style.selectedStateCount}>
                    {countSelectedState}{" "}
                    {countSelectedState > 1 ? "States" : "State"} Selected
                  </Text>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={style.thresholdSection}>
          <Text className={style.thresholdTitle}>
            Select Risk Threshold & Exclusions
          </Text>
          <div className={style.thresholdContent}>
            <div className={style.riskThresholdSection}>
              {selectedProduct == "BudgetLock" && (
                <>
                  <Text className={style.riskThresholdTitle}>
                    Select cities between economic activity percentiles
                  </Text>
                  <div>
                    <Select
                      style={{
                        width: 66,
                        marginTop: "10px",
                        marginBottom: "15px",
                      }}
                      onChange={(val) => setlowerEconomicPercentile(val)}
                      value={lowerEconomicPercentile}
                    >
                      {new Array(11).fill().map((d, i) => (
                        <Option
                          value={i * 10}
                          disabled={
                            upperEconomicPercentile &&
                            i * 10 >= upperEconomicPercentile
                          }
                        >
                          {i * 10}
                        </Option>
                      ))}
                    </Select>
                    <span
                      style={{ margin: "0 20px" }}
                      className={style.radioButtonText}
                    >
                      and
                    </span>
                    <Select
                      style={{
                        width: 66,
                        marginTop: "10px",
                        marginBottom: "15px",
                      }}
                      onChange={(val) => setupperEconomicPercentile(val)}
                      value={upperEconomicPercentile}
                      disabled={Number(lowerEconomicPercentile) == 100}
                    >
                      {new Array(11).fill().map((d, i) => (
                        <Option
                          value={i * 10}
                          disabled={
                            lowerEconomicPercentile &&
                            i * 10 <= lowerEconomicPercentile
                          }
                        >
                          {i * 10}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </>
              )}
              <Text className={style.riskThresholdTitle}>Trigger Duration</Text>
              <div>
                <Select
                  placeholder="Trigger duration"
                  style={{
                    width: 120,
                    marginTop: "10px",
                    marginBottom: "15px",
                  }}
                  onChange={(val) => settriggerDurationValue(val)}
                  value={triggerDurationValue}
                  disabled={selectedProduct === "LoanProtect"}
                >
                  <Option value="1 month">1 month</Option>
                  <Option value="2 months">2 months</Option>
                </Select>
              </div>
              {/* </div> */}
              <Text className={style.riskThresholdTitle}>
                Threshold for any&nbsp;
                {analysisType == "square"
                  ? "square"
                  : analysisType == "zip"
                  ? "zip code"
                  : "city"}
                &apos;s performance
              </Text>
              <div className={style.riskThresholdContent}>
                <Input
                  className={
                    !thresholdRangeError
                      ? style.riskThresholdInput
                      : style.riskThresholdErrorInput
                  }
                  disabled={disableWhileLoading}
                  value={thresholdValue}
                  onChange={handleThresholdValueChange}
                  onKeyDown={(evt) => handleKeyDown(evt)}
                  onKeyUp={(evt) => handleThresholdInput(evt)}
                />
                <span className={style.riskThresholdPercentSymbol}>%</span>
                <div className={style.riskThresholdText}>
                  <Tooltip
                    title={toolTipText}
                    trigger="click"
                    overlayStyle={{ whiteSpace: "pre-line" }}
                  >
                    <Button
                      style={{ padding: "0" }}
                      className={style.readMoreButton}
                      type="link"
                      size="small"
                    >
                      {/* Read more */}
                      <QuestionCircleFilled
                        className={style.questionCircleIcon}
                      />
                    </Button>
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={style.buttonSection}>
          <div className={style.resetRunSection}>
            <Button
              className={style.resetButton}
              type="link"
              onClick={() => handleResetButtonClick()}
              disabled={disableWhileLoading}
            >
              RESET
            </Button>
            <Popover
              content={
                <Text type="secondary">
                  Clear all settings and return the page to the state it was in,
                  when first entered.
                </Text>
              }
              title={
                <div style={{ width: "100%", display: "flex" }}>
                  RESET
                  <span style={{ position: "absolute", right: "23px" }}>
                    <CloseOutlined
                      style={{ color: "rgba(0, 0, 0, 0.45)" }}
                      onClick={() =>
                        setpopover((prev) => ({ ...prev, reset: false }))
                      }
                    />
                  </span>
                </div>
              }
              trigger="click"
              visible={popover.reset}
              onVisibleChange={() =>
                setpopover((prev) => ({
                  ...prev,
                  reset: !popover.reset,
                }))
              }
            >
              <Button
                type="link"
                style={{ padding: 0, verticalAlign: "text-top" }}
                shape="circle"
              >
                <QuestionCircleFilled className={style.questionCircleIcon} />
              </Button>
            </Popover>
            <span style={{ float: "right" }}>
              <Button
                className={style.runButton}
                type="primary"
                onClick={() => analyzeData()}
                disabled={disableRunButton}
              >
                IMPOSE CAPS
              </Button>
              <Popover
                content={
                  <Text type="secondary">
                    Assuming that you're satisfied with these settings, see the
                    distribution of economic activity in your selected sales
                    region and impose a set of caps to manage concentration
                    risk.
                  </Text>
                }
                title={
                  <div style={{ width: "100%", display: "flex" }}>
                    IMPOSE CAPS
                    <span style={{ position: "absolute", right: "23px" }}>
                      <CloseOutlined
                        style={{ color: "rgba(0, 0, 0, 0.45)" }}
                        onClick={() =>
                          setpopover((prev) => ({ ...prev, run: false }))
                        }
                      />
                    </span>
                  </div>
                }
                trigger="click"
                visible={popover.run}
                onVisibleChange={() =>
                  setpopover((prev) => ({
                    ...prev,
                    run: !popover.run,
                  }))
                }
              >
                <Button type="link" style={{ padding: 0 }} shape="circle">
                  <QuestionCircleFilled className={style.questionCircleIcon} />
                </Button>
              </Popover>
            </span>
          </div>
        </div>
      </Spin>
      <StateRiskCapsModal
        key={selectedStates}
        visible={saveSettingsModalVisible}
        maxRiskPerStateInMillions={maxRiskPerStateInMillions}
      />
    </div>
  );
};

export default Dashboard;
