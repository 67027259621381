import React, { useState, useEffect } from "react";
import { Modal, Input, Typography, Button, message } from "antd";
import { STATES } from "../../constants/Constants";
const { Text } = Typography;

const StateRiskCapsModal = ({
  visible,
  onClose,
  maxRiskPerStateInMillions,
  saveTrue = false,
}) => {
  const [localRiskCaps, setLocalRiskCaps] = useState({});

  useEffect(() => {
    if (visible) {
      // Try to get risk caps from localStorage first
      const savedRiskCaps = JSON.parse(localStorage.getItem("riskCaps"));
      if (savedRiskCaps && Object.keys(savedRiskCaps).length > 0) {
        setLocalRiskCaps(savedRiskCaps);
      } else if (
        maxRiskPerStateInMillions &&
        Object.keys(maxRiskPerStateInMillions).length > 0
      ) {
        // If localStorage is empty, use the backend-provided values
        setLocalRiskCaps(maxRiskPerStateInMillions);
        localStorage.setItem(
          "riskCaps",
          JSON.stringify(maxRiskPerStateInMillions)
        );
      } else {
        // Fallback to an empty object if neither exists
        setLocalRiskCaps({});
      }
    }
  }, [visible, maxRiskPerStateInMillions]);

  // Input change handler
  const handleInputChange = (e, stateCode) => {
    const newVal = e.target.value;
    setLocalRiskCaps((prev) => ({
      ...prev,
      [stateCode]: newVal,
    }));
  };

  // Save handler
  const handleSave = () => {
    // Validate that every state's value is non-empty, numeric, and > 0.
    const allValid = Object.keys(localRiskCaps).every((key) => {
      const val = localRiskCaps[key];
      const parsed = parseFloat(val);
      return val !== "" && !isNaN(parsed) && parsed > 0;
    });

    if (!allValid) {
      message.error(
        "Please fill in all state risk cap values with numbers greater than 0."
      );
      return;
    }

    const formattedData = Object.keys(localRiskCaps).reduce((acc, key) => {
      acc[key] = parseFloat(localRiskCaps[key]);
      return acc;
    }, {});

    // Update localStorage
    localStorage.setItem("riskCaps", JSON.stringify(formattedData));

    // Pass the data back to the parent
    onClose(formattedData);
  };

  // Cancel handler
  const handleCancel = () => {
    // Reset state to original (preloaded) values.
    setLocalRiskCaps({ ...maxRiskPerStateInMillions });
    onClose();
  };

  // Check if there are any states to display
  const hasStates = STATES.some((regionBlock) =>
    regionBlock.states.some(
      (state) => maxRiskPerStateInMillions[state.value] !== undefined
    )
  );

  return (
    <Modal
      visible={visible}
      onCancel={handleCancel}
      footer={null}
      title="State Risk Caps"
      width="95%"
      destroyOnClose
    >
      <div style={{ marginBottom: 20 }}>
        {hasStates ? (
          <div
            style={{
              display: "flex",
              gap: "3.5%",
              flexWrap: "nowrap",
              overflowX: "auto",
            }}
          >
            {STATES.map((regionBlock) => {
              const { region, states } = regionBlock;
              // Filter only those states that are in maxRiskPerStateInMillions.
              const filteredStates = states.filter(
                (s) => maxRiskPerStateInMillions[s.value] !== undefined
              );
              if (filteredStates.length === 0) return null;
              return (
                <div key={region} style={{ minWidth: "10%" }}>
                  <Text
                    strong
                    style={{
                      fontSize: "15px",
                      display: "block",
                      marginBottom: "8px",
                    }}
                  >
                    {region}
                  </Text>
                  {filteredStates.map((stateObj) => {
                    const { display: stateName, value: stateCode } = stateObj;
                    const currentVal = localRiskCaps[stateCode] ?? "";
                    return (
                      <div
                        key={stateCode}
                        style={{
                          display: "flex",
                          marginBottom: "10px",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            width: "8rem",
                            whiteSpace: "normal",
                            paddingRight: "6px",
                          }}
                        >
                          {stateName}
                        </div>
                        <Input
                          value={currentVal}
                          onChange={(e) => handleInputChange(e, stateCode)}
                          suffix="M USD"
                          style={{ width: "8rem" }}
                        />
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        ) : (
          <div style={{ textAlign: "center", marginTop: "20px" }}>
            <Text type="secondary" style={{ fontSize: "16px" }}>
              No states found or 'No Caps' selected. Please change the option to
              'Impose Caps' and ensure the state data is filled.
            </Text>
          </div>
        )}
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          borderTop: "1px solid #F0F0F0",
          paddingTop: 16,
          marginTop: 16,
        }}
      >
        <div style={{ flex: 1 }}>
          <Button type="link" onClick={handleCancel} style={{ paddingLeft: 0 }}>
            CANCEL
          </Button>
        </div>
        {saveTrue && (
          <Button type="primary" onClick={handleSave}>
            SAVE
          </Button>
        )}
      </div>
    </Modal>
  );
};

export default StateRiskCapsModal;
