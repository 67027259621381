import React from "react";
import { Typography } from "antd";
import style from "./overview.module.scss";

const { Text } = Typography;

const OutlockOverview = () => (
  <>
    <div className={style.overviewTitleSection}>
      <Text className={style.title}>
        Underwriting Disruption Parachute - Overview
      </Text>
    </div>
    <div className={style.overviewText}>
      <ul>
        <li>
          The engine is built upon roughly 450 million data points covering
          several years of financial transactions across the USA. Those data
          points in turn depend upon the aggregation of tens of billions of
          individual transactions during this period.
        </li>
        <li>
          It operates upon roughly 22,000 squares of side approximately 1.5
          miles each, which Machine Cover deems DP-qualified, i.e. any suitable
          interested party located in these squares may buy a Disruption
          Parachute. These 'DP-qualified' squares may be used as is, or in the
          form of zip codes which contain them.
        </li>
        <li>
          If a policyholder buys a Disruption Parachute, their Disruption
          Parachute will trigger (i.e. they are owed a predetermined amount of
          money) if the square/zip code they happen to belong to, experiences a
          drop in economic activity that is worse than the nation as a whole,
          the drop is not predictable and economic activity continues in its
          post-drop state.
        </li>
        <li>
          This Underwriting Engine explores what the probability of such a
          trigger is, depending on what you set on the next page.
        </li>
      </ul>
    </div>
  </>
);

export default OutlockOverview;
